<template lang="">
    <div class="row" v-if="teleconsultation">
        <a @click="$router.go(-1)" class="back"
            ><i
                class="fa fa-arrow-left pb-3 cursor-pointer mt-2"
                aria-hidden="true"
            ></i
        ></a>
        <h5>Détails de la téléconsultation</h5>
        <b-tabs content-class="mt-3" justified>
            <b-tab title="Résumé" active>
                <ListMotifs :teleconsultation="teleconsultation" />
            </b-tab>
            <b-tab title="Antécédents">
                <ListAntedents :teleconsultation="teleconsultation" />
            </b-tab>
            <b-tab title="Allergies">
                <ListAllergies :teleconsultation="teleconsultation" />
            </b-tab>
            <b-tab title="Anamnèses">
                <ListAnamneses :teleconsultation="teleconsultation" />
            </b-tab>
            <b-tab title="Examens cliniques">
                <ListExamenCliniques :teleconsultation="teleconsultation" />
            </b-tab>
            <b-tab title="Examens complémentaires">
                <ListExamenComplementaires
                    :teleconsultation="teleconsultation"
                />
            </b-tab>
            <b-tab title="Diagnostics">
                <ListDiagnostic :teleconsultation="teleconsultation" />
            </b-tab>
            <b-tab title="Rendez-vous">
                <ListRendezVous :teleconsultation="teleconsultation" />
            </b-tab>
            <b-tab title="Ordonnances">
                <ListOrdonance :teleconsultation="teleconsultation" />
            </b-tab>
        </b-tabs>
    </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import moment from 'moment';
import ListMotifs from './../../views/Admin/Teleconsultation/motifs/ListMotifs';
import ListAnamneses from './../../views/Admin/Teleconsultation/anamneses/ListAnamneses';
import ListAntedents from './../../views/Admin/Teleconsultation/antecedents/ListAntedents';
import ListAllergies from './../../views/Admin/Teleconsultation/allergies/ListAllergies';
import ListExamenCliniques from './../../views/Admin/Teleconsultation/examenCliniques/ListExamenCliniques';
import ListExamenComplementaires from './../../views/Admin/Teleconsultation/examenComplementaires/ListExamenComplementaires';
import ListRendezVous from './../../views/Admin/Teleconsultation/rendezVous/ListRendezVous';
import ListDiagnostic from './../../views/Admin/Teleconsultation/diagnostics/ListDiagnostic';
import ListOrdonance from './../../views/Admin/Teleconsultation/ordonance/ListOrdonance';

import { onMounted, computed } from 'vue';

const store = useStore();
const router = useRoute();

const teleconsultation = computed(
    () => store.state.teleconsultations.teleconsultation
);

onMounted(() => {
    store.dispatch('teleconsultations/getTeleconsultation', router.params.uuid);
});
</script>
