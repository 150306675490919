import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import { createPinia } from 'pinia';
import BootstrapVue3 from 'bootstrap-vue-3';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import './assets/scss/style.scss';
import '@vuepic/vue-datepicker/dist/main.css';
import piniaPersist from 'pinia-plugin-persist';

import ToastPlugin from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import VueSocialSharing from 'vue-social-sharing';

const pinia = createPinia();
pinia.use(piniaPersist);

createApp(App)
    .use(store)
    .use(pinia)
    .use(router)
    .use(BootstrapVue3)
    .use(ToastPlugin)
    .use(VueSocialSharing)
    .use(VueSweetalert2)
    .directive('can', (el: any, binding, vnode: any) => {
        const permissions = store.state.users?.user?.permissions ?? [];
        if (permissions.indexOf(binding.value) !== -1) {
            return (vnode.el.hidden = false);
        } else {
            return (vnode.el.hidden = true);
        }
    })
    .directive('is', (el: any, binding, vnode: any) => {
        const roles = store.state.users.user.roles ?? [];
        if (roles.find((item: any) => item.name == binding.value)) {
            return (vnode.el.hidden = true);
        } else {
            return (vnode.el.hidden = false);
        }
    })
    .mount('#app');
