import Axios from '../api';
import { Pagination } from '@/_types/pagination';

class OrdonnanceService {
    path: string;

    constructor() {
        this.path = '/ordonnances';
    }

    async getOrdonnances(page = 1): Promise<Pagination> {
        return await Axios.get(`${this.path}?page=${page}`);
    }

    async searchEOrdonnance(page = 1, search: string): Promise<Pagination> {
        return await Axios.get(`${this.path}?page=${page}&search=${search}`);
    }

    async createdOrdonnance(ordonance: any): Promise<any> {
        return await Axios.post(this.path, ordonance);
    }

    async deleteOrdonnance(
        relation_id: number,
        ordonnance_id: number,
        relation: string
    ): Promise<any> {
        return await Axios.delete(
            `${this.path}/${relation_id}/${ordonnance_id}/${relation}`
        );
    }
}

export default new OrdonnanceService();
