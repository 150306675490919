import Axios from '../api';
import { Emailsms } from '../_storeV2/email-sms/index';

class EmailsmsAnalyse {
    path: string;
    user_path: string;
    groupe: string;
    role: string;
    constructor() {
        (this.path = '/email-sms'),
            (this.user_path = '/patients-et-souscripteurs');
        this.groupe = '/groupes';
        this.role = '/roles/groupes';
    }
    async getEmailsmss(page = 1, page_size = 10, search = ''): Promise<any> {
        return await Axios.get(
            `${this.path}?page_size=${page_size}&page=${page}&search=${search}`
        );
    }
    async getEmailsms(uuid: number): Promise<any> {
        return await Axios.get(`${this.path}/${uuid}`);
    }
    async searchPatient(search: string): Promise<any> {
        return await Axios.get(`${this.user_path}?search=${search}`);
    }

    async createEmailsms(data: any): Promise<any> {
        return await Axios.post(this.path, data);
    }
    async deleteEmailsms(id: any): Promise<any> {
        return await Axios.delete(`${this.path}/${id}`);
    }

    async updateEmailsms(uuid: any, data: any): Promise<any> {
        return await Axios.patch(`${this.path}/${uuid}`, data);
    }

    async printEmailsms(bulletin_id: number): Promise<any> {
        return await Axios.get(`${this.path}/print/${bulletin_id}`);
    }

    async groupes(): Promise<any> {
        return await Axios.get(`${this.groupe}?list=all`);
    }

    async roles(): Promise<any> {
        return await Axios.get(`${this.role}`);
    }
}

export default new EmailsmsAnalyse();
