<template lang="">
    <div class="default"> 
        <div>
            <router-link to="/email-sms/create" type="button" 
                class="btn btn-primary p-2 text-h5 fw-bold teleconsultation-btn d-flex align-items-center">
                <i class="fa-solid fa-plus"></i> <span class="px-1">Nouveau Message</span>
            </router-link>
        </div>
        <!-- <div>
            <userSelect btn_text="Nouveau Mail" routeName="/email-sms/create"/>
        </div> -->
        <div class="py-md-4 d-md-flex justify-content-between w-100 pt-4 pb-3">
            <div class="mt-3 mr-md-auto">
                    <h4 class="text-h2 fw-bold">Listing des mails</h4>
            </div>
            <div class="d-md-flex align-items-end justify-content-end float-md-end">
                <ul class="list-none p-0 d-md-flex align-items-end mr-0 pr-0 gap-2">
                    <li class="mt-2 mt-md-0"><input type="text" v-model="search" @keyup="searchPresc($event)" class="form-control" placeholder="rechercher par utilisateur"></li>
                </ul>
            </div>
        </div>
        <div class="d-flex mt-4" v-if="!store.getEmailsms">
            <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin m-auto"/>
        </div>
        <div class="">
            <div class="bg-transparent d-none d-md-block">
                <div class="rounded taille mb--3 align-items-center row px-1">
                    <div class="col-md">
                            <ul class="list-none px-2 py-2 row-lg table-header gap-2 justify-content-between d-flex align-items-center">
                                <li class="col-lg nom">Détails</li>
                                <li class="col-lg">Objet</li>
                            </ul>
                        </div>
                        <div class="col-md-2">
                            <ul class="list-none px-2 py-2 row-lg table-header gap-2 justify-content-between d-flex align-items-center">
                                <li class="col-lg d-none d-lg-block">Date</li>
                            </ul>
                        </div>
                </div>
                <div class="rounded bg-white mb-1 align-items-center row px-1"  v-for="item in store.getEmailsms?.data" :key="item.id" >
                    <div @click="openShow(item)" @mouseleave="mouse = false" @mouseover="mouse = true" :class="mouse? 'col-md cursor-pointer hoverTd':'col-md cursor-pointer'">
                        <ul class="list-none d-flex row-lg default fwn px-2 py-3 mb-2 gap-2 justify-content-between align-items-center" id="modalTC">
                                <!--  card-active <li class="col-lg "><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></li> -->
                                <li class="col-lg d-flex align-items-center gap-1" >
                                    <span class="m-0 fwb">
                                        {{ item.roles_count == 0 ? '' : item.roles_count+' rôle(s), ' }}
                                        {{ item.users_count == 0 ? '' : item.users_count+' utilisateur(s), ' }}
                                        {{ item.groupes_count == 0 ? '' : item.groupes_count+' groupe(s)' }}
                                    </span>
                                </li>
                                <li class="col-lg d-md-flex align-items-center">
                                    <span class="default fwb cursor-pointer d-flex justify-content-between"> <span> {{item.subject}}</span></span>
                                </li>
                        </ul>
                    </div>
                    <div class="col-md-2">
                        <ul class="list-none px-2 py-2 row-lg table-header gap-2 justify-content-between d-flex align-items-center">
                            <li class="col-lg d-none d-lg-block">
                                {{ moment(item?.created_at).format("DD-MM-YYYY") }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- Mobile version -->
            <div class="bg-transparent d-block d-md-none">
                    <div  v-for="item in store.getEmailsms?.data" :key="item.id" class="list-group list-group-flush">
                        <div class="d-flex flex-row justify-content-between w-100 p-1 my-1 list-group-item" v-b-toggle="'collapse'+item.id"  @click="openShow(item)">
                            <div class="" >
                                <span for="" class="my-2 text-h5 fw-semibold">{{ item.roles_count == 0 ? '' : item.roles_count+' rôle(s), ' }}
                                        {{ item.users_count == 0 ? '' : item.users_count+' utilisateur(s), ' }}
                                        {{ item.groupes_count == 0 ? '' : item.groupes_count+' groupe(s)' }}</span>
                                <h6 class=" colorGrey text-uppercase pt-1 text-h5">{{item.subject}} </h6>
                            </div>
                            <div class="when-open">
                                <i class="fa fa-angle-down px-2 text-dark text-h5"></i>
                            </div>
                            <div class="when-closed">
                                <i class="fa fa-angle-right px-2 text-dark text-h5 "></i>
                            </div>
                        </div>          
                        <!-- <b-collapse :id="'collapse'+item.id" class="mb-2 w-100">
                                <div class="container-fluid w-100">
                                        <div class="row py-1 list-none w-100">
                                            <li class="list-none mb-3 rounded card-text urgence-3 px-1 py-2 fwb">Niveau d'urgence : {{item?.niveau_urgence_id}} </li>
                                            <li  v-if="item.option_financements[0]" class="list-none mb-2 card-text default" v-b-toggle="'collapse-2'+item.id"><span  v-if="item.option_financements[0]" class="font-weight-bold fwb">Option financement</span> : <span class="w-100 default cursor-pointer d-flex justify-content-between" :id="`popover-5-${item.id}`">
                                                <span >{{item.option_financements[0]?.libelle}}</span> 
                                                <span><i class="fa fa-circle-info"></i></span></span>
                                                <b-collapse :id="'collapse-2'+item.id" placement="right" triggers="hover">
                                                    <div class="" v-for="finance in item.option_financements">
                                                        <b-badge> {{truncate(finance.libelle, 10)}} </b-badge>
                                                    </div>
                                                </b-collapse>
                                            </li>
                                            <li v-if="item.raison_prescriptions[0]" class="list-none mb-2 card-text default" v-b-toggle="'collapse-3'+item.id"><span class="font-weight-bold fwb">Raison prescription</span> : 
                                                <span class="w-100 default cursor-pointer d-flex justify-content-between" :id="`popover-6-${item.id}`"><span >{{item.raison_prescriptions[0]?.libelle}}</span> <span><i class="fa fa-circle-info"></i></span>
                                                </span>
                                                <b-collapse :id="'collapse-3'+item.id">
                                                    <div class="" v-for="raison in item.raison_prescriptions">
                                                        <b-badge>{{raison.libelle}}</b-badge>
                                                    </div>
                                                </b-collapse>
                                            </li>
                                            <li class="list-none mb-2 default"><span class="row">
                                                <span class="font-weight-bold fwb col-3">Motif :</span>  
                                                    <span v-html="truncate(item.motif,45)" class="col-9 motifpresc"></span>
                                                </span></li>
                                            
                                            <li class="list-none mb-2 default"><span class="font-weight-bold card-text default fwb">Date</span> : {{moment(item?.date_heure).format("DD-MM-YYYY") }}</li>
                                          
                                        </div>
                                        
                                        <div class="d-flex mb-2 justify-content-center mt-2 w-100" v-can="'Afficher une alerte'">
                                            <button v-can="'Afficher une e-prescription'" @click="openShow(item), SelectPatient(item.patient_id, datas)" size="sm" class="btn btn-primary px-2 fw-bold text-h5 w-100">
                                                <i class="fa fa-eye px-1"></i><span class="px-1">Voir plus de details</span>
                                            </button>
                                        </div>
                                        <div class="d-flex mb-2 justify-content-center mt-2 w-100">
                                            <button v-can="'Modifier une e-prescription'" @click="openShow(item, true), SelectPatient(item.patient_id, datas)" size="sm" class="btn btn-outline-dark px-2 fw-bold text-h5 w-100">
                                                <i class="fa fa-edit px-1"></i><span class="px-1">Modifier</span>
                                            </button>
                                        </div>
                                        <div class="d-flex mb-2 justify-content-center mt-2 w-100">
                                            <button v-can="'Supprimer une e-prescription'" @click="deletePrescription(item.id)" size="sm" class="btn btn-outline-danger px-2 fw-bold text-h5 w-100">
                                                <i class="fa fa-trash px-1"></i><span class="px-1">Supprimer</span>
                                            </button>
                                        </div>
                                </div>
                        </b-collapse> -->
                    </div>
                </div> 
                
                <nav aria-label="Page navigation example" v-if="store.getEmailsms">
                        <ul class="pagination justify-content-end" id="pagination-demo">
                            <li :class="`page-item ${store.getEmailsms.current_page == store.getEmailsms.from ? 'disabled' : ''}`"><a class="page-link" href="#" @click="previous(store.getEmailsms.current_page)" tabindex="-1" aria-disabled="true"> <span aria-hidden="true">&laquo;</span>
                                <span class="sr-only">Previous</span></a>
                            </li>
                            <li :class="`page-item ${store.getEmailsms.current_page == n ? 'active text-danger' : ''}`" v-for="n in pages" @click="changePage(n)"><a class="page-link" href="#">{{ n }}</a></li>
                            <li class="page-item">
                                <li :class="`page-item ${store.getEmailsms.current_page == store.getEmailsms.last_page ? 'disabled' : ''}`"><a class="page-link" href="#" @click="next(store.getEmailsms.current_page)" tabindex="-1" aria-disabled="true"> <span aria-hidden="true">&raquo;</span>
                                    <span class="sr-only">Next</span></a>
                                </li>
                            </li>
                        </ul>
                </nav>
        </div>
    </div>
</template>
<script setup>
import userSelect from '@/components/organisms/UserSelect.vue'
import { computed, ref, reactive,onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import moment from "moment";
import { useEmailsms } from "@/_storeV2/email-sms";
import { useStore } from 'vuex';
import AddEmailSms from '@/views/Admin/emailSms/AddEmailSms.vue';
import { useVuelidate } from '@vuelidate/core';

    const store = useEmailsms();
    const router = useRouter();
    const route = useRoute();
    const search = ref('');
    function SelectPatient(id, associations) {
            store.dispatch('patient/fetchPatient', { id: id, associations: associations })
        }
        

    function truncate(str = "", n){
            return (str?.length > n) ? str.slice(0, n-1) + '...' : str;
        }
    const page_size = ref(5)
    const changePage = page => {
        store.fetchEmailsmss({ page: page, page_size: page_size.value });
    }
    const previous = page => {
        page--;
        store.fetchEmailsmss( { page: page, page_size: page_size.value });
    }

    const next = page => {
        page++;
        store.fetchEmailsmss( { page: page, page_size: page_size.value });
    }

    const changeSize = () => {
        store.fetchEmailsmss({ page: 1, page_size: page_size.value });
    }

    const pages= computed(() =>{
                let numShown = 3;   // This sets the number of page tabs
                if (store.getEmailsms.last_page!= undefined)
                    {
                    numShown = Math.min(numShown, store.getEmailsms.last_page);
                    let first = store.getEmailsms.current_page - Math.floor(numShown / 2);
                    first = Math.max(first, 1);
                    first = Math.min(first, store.getEmailsms?.last_page - numShown + 1);
                    // console.log([...Array(numShown)]);
                    return [...Array(numShown)].map((k,i) => i + first);
                }
            })
    const searchPresc = event => {
        search.value = event.target.value;
        if(event.target.value.length >= 3){
            store.fetchEmailsmss({ page: 1, page_size: page_size.value, search: event.target.value });
        }
        if(event.target.value.length == 0){
            store.fetchEmailsmss({ page: 1, page_size: page_size.value, search: search.value });
        }
    }
    const openShow =(item)=>{
        router.push({
            name: 'email-sms-affichage',
            // params: {'uuid': prescription.uuid},
            params: {'uuid' : item.uuid},
        })
        }
    onMounted(() => {
        store.fetchEmailsmss({page: 1, page_size: 5, search: ""});
    })
</script>