import Axios from '../api';
import { Pagination } from '@/_types/pagination';

class ExamenComplementaireService {
    path: string;

    constructor() {
        this.path = '/examen_complementaires';
    }

    async getExamen_complementaires(page = 1): Promise<Pagination> {
        return await Axios.get(`${this.path}?page=${page}`);
    }

    async searchExamens(search: string, page = 1): Promise<Pagination> {
        return await Axios.get(`${this.path}?page=${page}&search=${search}`);
    }

    async createExamen_complementaire(
        examen_complementaire: any
    ): Promise<any> {
        return await Axios.post(this.path, examen_complementaire);
    }

    async deleteExamen_complementaire(
        relation_id: number,
        examen_complementaire_id: number,
        relation: string
    ): Promise<any> {
        return await Axios.delete(
            `${this.path}/${relation_id}/${examen_complementaire_id}/${relation}`
        );
    }
}

export default new ExamenComplementaireService();
