<template lang="">
    <div>
        <router-view />
    </div>
</template>
<script>
export default {
    name: 'email-sms',
};
</script>
<style lang=""></style>
