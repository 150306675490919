import Axios from '../api';

class bigBlueButtonService {
    path: string;
    constructor() {
        this.path = '/mettings';
    }

    async createMetting(user_id: number): Promise<any> {
        return await Axios.get(`${this.path}/create/${user_id}`);
    }

    async joinMetting(user_id: number): Promise<any> {
        return await Axios.get(`${this.path}/join/${user_id}`);
    }
}

export default new bigBlueButtonService();
