<template lang="">
    <div class="py-4 bg-white px-3 rounded-1 shadow dark prescription">
        <div class="d-flex justify-content-between">
            <div>
                <h4 class="">Nouveau {{ service == 1 ? 'Email' : 'SMS' }}</h4>
            </div>
            <div>
                <b-form-radio-group
                    id="radio-group-1"
                    v-model="service"
                    :options="services"
                    name="radio-options"
                ></b-form-radio-group>
            </div>
        </div>
        <hr />
        <form class="prescription-form">
            <div class="row my-4">
                <p>
                    Vous avez la possibilité d'envoyer des emails ou des SMS à
                    des groupes d'utilisateurs, des rôles d'utilisateurs et/ou à
                    des utilisateurs spécifiques.
                </p>
                <div class="mb-4">
                    <label for="">Groupes :</label>
                    <div class="border rounded p-2">
                        <b-form-checkbox-group
                            v-model="state.groupes"
                            :options="store.groupes ?? []"
                            class="mb-3 d-flex flex-wrap"
                            value-field="id"
                            text-field="nom"
                            disabled-field="notEnabled"
                        ></b-form-checkbox-group>
                    </div>
                    <!-- <small v-if="v$.option_financement.$error && v$.option_financement.$invalid" class="red list-none ">
                        {{ "sélectionner au moins une option financiere" }}
                    </small> -->
                </div>
                <div class="mb-4">
                    <label for="">Rôles :</label>
                    <div class="border rounded p-2">
                        <b-form-checkbox-group
                            v-model="state.roles"
                            :options="store.roles ?? []"
                            class="mb-3 d-flex flex-wrap"
                            value-field="id"
                            text-field="name"
                            disabled-field="notEnabled"
                        ></b-form-checkbox-group>
                    </div>
                </div>
                <div class="col-md-6" v-if="service == 1">
                    <label for="">Utilisateurs :</label>
                    <div>
                        <div class="w-100 d-block d-md-flex align-items-center">
                            <VueMultiselect
                                v-model="state.users"
                                :options="store.searchUserByEmails"
                                :multiple="true"
                                @search-change="DataPatient"
                                placeholder="Rechercher les utilisateurs"
                                track-by="id"
                                label="name"
                            >
                            </VueMultiselect>
                        </div>
                    </div>
                </div>
                <div v-else class="col-md-12">
                    <label for="">Utilisateurs :</label>
                    <div>
                        <div class="w-100 d-block d-md-flex align-items-center">
                            <VueMultiselect
                                v-model="state.users"
                                :options="store.searchUserByPhone"
                                :multiple="true"
                                @search-change="DataPatient"
                                placeholder="Rechercher les utilisateurs"
                                track-by="id"
                                label="name"
                            >
                            </VueMultiselect>
                        </div>
                    </div>
                </div>
                <div class="col-md-6" v-if="service == 1">
                    <label for=""
                        >Objet de l'email<span class="red">*</span> :</label
                    >
                    <div>
                        <input
                            type="text"
                            class="form-control quantity"
                            v-model="state.subject"
                        />
                    </div>
                    <small v-if="v$.subject.$error" class="red list-none">
                        {{ "Entrer l'objet de l'email" }}
                    </small>
                </div>
                <div class="col-md-12">
                    <label for="">Contenu<span class="red">*</span> :</label>
                    <div>
                        <Editor
                            api-key="mm3cmjvl9px5ksezprcy72un7g456ii25fpje4frqemgkrqn"
                            :init="{
                                height: 300,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount',
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
                                alignleft aligncenter alignright alignjustify | \
                                bullist numlist outdent indent | removeformat | help',
                            }"
                            v-model="state.contenu"
                        />
                        <small v-if="v$.contenu.$error" class="red list-none">
                            {{ 'Entrer un contenu' }}
                        </small>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-md-end my-4">
                <span
                    @click="submitForm"
                    :disabled="!validation"
                    :class="
                        !validation || Status
                            ? 'btn btn-light mx-1 d-flex align-items-center'
                            : 'btn btn-primary mx-1 d-flex align-items-center'
                    "
                >
                    <span
                        ><b-spinner
                            v-if="Status"
                            variant="default"
                            label="Spinning"
                        ></b-spinner
                    ></span>
                    <span class="mx-2">Envoyer</span>
                </span>
            </div>
        </form>
    </div>
</template>

<script setup>
import AdministrativeData from '@/components/organisms/AdministrativeData.vue';
import VueMultiselect from 'vue-multiselect';
import * as ECT from '@whoicd/icd11ect';
import { useVuelidate } from '@vuelidate/core';
import '@whoicd/icd11ect/style.css';
import Modal from '@/views/Admin/Teleconsultation/Modal.vue';
import Editor from '@tinymce/tinymce-vue';
import _ from 'lodash';
import { useStore } from 'vuex';
import { storeToRefs } from 'pinia';
import { required } from '@vuelidate/validators';
import MultiStepsForm from '@/components/form/MultiStepsForm.vue';
import { useRouter, useRoute } from 'vue-router';
import { ref, reactive, inject, onMounted, computed, watch } from 'vue';
import { useEmailsms } from '@/_storeV2/email-sms';
import Datepicker from '@vuepic/vue-datepicker';

const swal = inject('$swal');
const loading = computed(() => store.getLoading);
const store = useEmailsms();
const xstore = useStore();
const router = useRouter();
const route = useRoute();
// Globale
const props = defineProps({
    state: {},
    v$: {},
});
const Status = ref(false);
const steps = reactive([
    { title: 'Documentation', slotName: 'step1' },
    { title: 'Données', slotName: 'step2' },
    // { title: 'Examen(s)', slotName: 'step3' },
]);
const PlainteRules = computed(() => {
    return {
        description: { required },
        type: { required },
        date: { required },
    };
});
const services = ref([
    { value: 1, text: 'Emails', description: 'Emails' },
    { value: 2, text: 'SMS', description: 'SMS' },
]);

const dataEtablissement = computed(() => {
    return xstore.state.etablissement.etablissements?.data.map((item) => {
        return {
            id: item.id,
            name: item.name,
        };
    });
});
const patientSelect = ref(false);
const valid = ref(true);
const service = ref(1);
const doc = ref(true);
const elements = ref(false);
const dialogVisible = ref(false);
const height = ref(200);
const value = ref([]);
const maladie = ref([]);
const dataExamenPush = ref([]);
let data = ref([]);
const categorie = ref('');
const horaire = ref('');
const evenement = ref('');
const user = ref([]);
const searchData = ref([]);

const users = ref([]);

const getEtab = computed(() => {
    return store.getOnePrescription.etablissements || [];
});
const medicamentData = computed(() => {
    return store.getOnePrescription.medicaments || [];
});
const user_id = computed(() => xstore.state.users.user.id);
const medecin_id = xstore.state.users.user.id;
const patient_id = xstore.state.patient.select.user_id;

let option_financement = ref([]);
let etablissements = ref({});
let option_financemt = ref({});
let raison_presc = ref({});
let niveau_urg = ref({});
let ligne_temps = ref({});
let raison = ref({});

const motifs = computed(() => {
    raison.value = { id: getMotif.value };
    return raison.value;
});
const AvoidSubmitForm = () => {
    swal.fire({
        title: 'Voulez-vous vraiment quitter cette page?',
        text: "Vous n'avez pas terminé la saisie de ce formulaire",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#32325d',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, quitter',
        cancelButtonText: 'Non, annuler',
    }).then((result) => {
        if (result.isConfirmed) {
            router.go(-1);
        }
    });
};

//truncate word
const truncate = (word, maxLength) => {
    if (word.length > maxLength) {
        return word.slice(1, maxLength) + '...';
    }
    return word;
};

const state = reactive({
    users: ref([]),
    groupes: ref([]),
    roles: ref([]),
    subject: ref(''),
    contenu: '',
    isLoadingEtablissement: false,
    medecin_id: medecin_id,
});

const rules = reactive({
    users: {
        required: function () {
            return !state.groupes.value && !state.roles.value
                ? required(state.users.value)
                : true;
        },
    },
    groupes: {
        required: function () {
            return !state.users.value && !state.roles.value
                ? required(state.groupes.value)
                : true;
        },
    },
    roles: {
        required: function () {
            return !state.users.value && !state.groupes.value
                ? required(state.roles.value)
                : true;
        },
    },
    subject: {
        required: function () {
            return service.value == 1 ? required(state.subject.value) : true;
        },
    },
    contenu: { required },
});

const v$ = useVuelidate(rules, state);
v$.value.$touch();
const updateErrors = () => {
    v$.value.$touch();
};

// Écouteur de changement sur les champs groupes, roles et users
watch(
    [() => v$.value.groupes, () => v$.value.roles, () => v$.value.users],
    updateErrors
);

const validation = computed(() => {
    if (
        (v$.value.users || v$.value.groupes || v$.value.roles) &&
        !v$.value.subject.$error &&
        !v$.value.contenu.$error
    ) {
        valid.value = false;
        return true;
    } else {
        valid.value = true;
        return false;
    }
});
const submitForm = () => {
    let data = {
        ...state,
        users: state.users?.map((item) => item.id),
        service: service.value == 1 ? 'email' : 'sms',
    };
    v$.value.$touch();
    v$.value.$validate();
    if (validation.value) {
        store.createEmailsms(data);
    } else {
        console.log('false', v$);
    }
    Status.value = store.loading;
};

const DataPatient = (user) => {
    // isLoading.value = true;
    if (service.value == 1) {
        store.searchPatient(user);
    } else {
        store.searchPatientNumber(user);
    }
};

onMounted(() => {
    store.fetchGroupes();
    store.fetchRoles();
});
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
