<template lang="">
    <div class="row">
        <div class="col-md-12">
            <Create-Ordonance :teleconsultation_id="teleconsultation.id" />
            <div class="table-responsive">
                <table class="table caption-top">
                    <caption>
                        Liste des Ordonnances
                    </caption>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Ordonnance</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <div
                        class="position-absolute top-50 start-50"
                        v-if="loader"
                    >
                        <img
                            src="@/assets/icons/Spin.svg"
                            alt=""
                            class="btn-spin"
                        />
                    </div>
                    <tbody>
                        <tr
                            v-for="(
                                ordonnance, index
                            ) in teleconsultation.ordonnances"
                            :key="`ordonance${index}`"
                        >
                            <td>{{ index + 1 }}</td>
                            <td v-html="ordonnance?.description"></td>
                            <td class="d-flex">
                                <span
                                    class="rounded-circle border p-2 cursor-pointer btn-delete"
                                    @click="
                                        deleteOrdonnance(
                                            teleconsultation.id,
                                            ordonnance.id,
                                            'teleconsultations'
                                        )
                                    "
                                    ><i class="fa-solid fa-trash red"></i
                                ></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script setup>
import CreateOrdonance from './CreateOrdonance.vue';
import { ref, computed, inject } from 'vue';
import { useStore } from 'vuex';
const store = useStore();

const props = defineProps({
    teleconsultation: {},
});

const swal = inject('$swal');
const deleteOrdonnance = (relation_id, ordonnance_id, relation) => {
    swal.fire({
        title: 'Voulez-vous vraiment supprimer cette ordonnance?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Annuler',
        denyButtonText: `Supprimer`,
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isDenied) {
            store.dispatch('ordonnances/deleteOrdannance', {
                relation_id: relation_id,
                ordonnance_id: ordonnance_id,
                relation: relation,
            });
        }
    });
};

const loader = computed(() => store.state.ordonnances.loadingStatus);
</script>
