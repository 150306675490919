import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

import AllergieService from '@/_services/AllergieService';
import { useToast } from 'vue-toast-notification';

import { Allergie } from '@/_types';
import { Pagination } from '@/_types';

@Module({
    namespaced: true,
})
export default class AllergieModule extends VuexModule {
    allergies: Array<Allergie> = [];
    paginations: Pagination | null = null;
    loadingStatus = false;

    $toast = useToast();
    //getters allergies
    get gAllergies(): Array<Allergie> {
        return this.allergies;
    }

    get gpaginations() {
        return this.paginations;
    }

    //getters loading
    get gloadingStatus() {
        return this.loadingStatus;
    }

    @Mutation
    mloadingStatus(newStatus: boolean) {
        this.loadingStatus = newStatus;
    }

    //mutations allergies
    @Mutation
    mAllergies(paginations: Pagination) {
        this.paginations = paginations;
    }

    //actions allergies
    @Action
    fetchAllergiesAction(paginate = { page: 1 }) {
        AllergieService.getAllergies(paginate.page)
            .then((res: Pagination) => {
                this.context.commit('mAllergies', res.data.data);
            })
            .catch((error: any) => {
                console.log('error', error);
            });
    }

    @Action
    searchAllergies(element = { search: '', page: 1 }) {
        AllergieService.searchAllergie(element.search, element.page)
            .then((res: Pagination) => {
                this.context.commit('mAllergies', res.data.data);
            })
            .catch((error: any) => {
                console.log('error', error);
            });
    }

    @Action
    async createAllergie(allergie: Allergie | any) {
        this.context.commit('mloadingStatus', true);
        const allergies = {
            description: allergie.collection.map(
                (item: any) => item.description
            ),
            date: allergie.collection.map((item: any) => item.date),
            teleconsultation_id: allergie.teleconsultation_id,
        };
        await AllergieService.createdAllergie(allergies)
            .then((res: Pagination) => {
                this.context.commit('mloadingStatus', false);
                const teleconsultation = {
                    ...this.context.rootState.teleconsultations
                        .teleconsultation,
                    allergies: res.data,
                };
                this.context.commit(
                    'teleconsultations/getTeleconsultationHandler',
                    teleconsultation,
                    { root: true }
                );
                this.$toast.success('Allergie créée avec succès', {
                    position: 'top-right',
                });
                this.context.commit('mAllergies', res);
            })
            .catch((error: any) => {
                console.log('error', error);
            });
    }

    @Action
    async deleteAllergie(
        allergie = { relation_id: 0, allergie_id: 0, relation: '' }
    ) {
        this.context.commit('mloadingStatus', true);
        await AllergieService.deleteAllergie(
            allergie.relation_id,
            allergie.allergie_id,
            allergie.relation
        )
            .then((res: any) => {
                this.context.commit('mloadingStatus', false);
                const allergies =
                    this.context.rootState.teleconsultations.teleconsultation.allergies.filter(
                        (item: any) => item.id != res.data.data.id
                    );
                const teleconsultation = {
                    ...this.context.rootState.teleconsultations
                        .teleconsultation,
                    allergies: allergies,
                };
                this.context.commit(
                    'teleconsultations/getTeleconsultationHandler',
                    teleconsultation,
                    { root: true }
                );
                this.$toast.success('Allergie supprimée avec succès', {
                    position: 'top-right',
                });
            })
            .catch((error: any) => {
                console.log('error', error);
                this.context.commit('mloadingStatus', false);
            });
    }
}
