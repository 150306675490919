import Axios from '../api';

class OptionFinancementService {
    path: string;

    constructor() {
        this.path = '/options_financements';
    }

    async getOptionFinancement(): Promise<[] | any> {
        return await Axios.get(this.path);
    }
}

export default new OptionFinancementService();
