<template lang="">
    <Transition name="modal">
        <div v-if="show" class="modal-mask">
            <div class="modal-wrapper" @click.self="$emit('close')">
                <div class="modal-container">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ title }}</h5>
                        <button
                            type="button"
                            class="btn-close btn-close-white p-2"
                            id="fermeture"
                            aria-label="Close"
                            @click="$emit('close')"
                        ></button>
                    </div>

                    <div class="modal-body p-3">
                        <slot />
                    </div>

                    <!-- <div class="modal-footer">
                        <slot name="footer">
                        default footer
                        <button
                            class="modal-default-button"
                            @click="$emit('close')"
                        >OK</button>
                        </slot>
                    </div> -->
                </div>
            </div>
        </div>
    </Transition>
</template>
<script>
export default {
    props: {
        show: Boolean,
        title: '',
    },
};
</script>
