import { defineStore } from 'pinia';
import PriseEnCharge from '@/_services/PriseEnCharge';
import { useToast } from 'vue-toast-notification';
import router from '@/router';

export interface PriseEnCharge {
    id: number;
    uuid: string;
    libelle: string;
    data: any;
}

export interface TypesTeleconsultations {
    id: number;
    libelle: string;
    type: string;
}

export interface AnalyseMedicals {
    id: number;
    data: any;
}

export const usePriseEnCharge = defineStore('PriseEnCharge', {
    state: () => ({
        priseEnCharges: { data: [] } as PriseEnCharge,
        priseEnCharge: {} as PriseEnCharge[] | null,
        typesTeleconsultation: {} as TypesTeleconsultations[] | null,
        analyseMedicals: {} as AnalyseMedicals[] | null,
        teleconsultationPatient: {},
        $toast: useToast(),
        loading: false,
    }),

    getters: {
        getLoading(): boolean {
            return this.loading;
        },

        getPriseEnCharges(): Object {
            return this.priseEnCharges;
        },

        getPriseEnCharge(): Object | any {
            return this.priseEnCharge;
        },

        getExamenPatient(): Object | any {
            return this.analyseMedicals;
        },

        getTypesTeleconsultations(): Object | any {
            return this.typesTeleconsultation;
        },

        getTeleconsultationsPatient(): Object | any {
            return this.teleconsultationPatient;
        },
    },
    actions: {
        async fetchBonPriseEnCharge(
            paginate = { page: 1, page_size: 10, search: '' }
        ) {
            this.loading = true;
            await PriseEnCharge.getBonPriseEnCharges(
                paginate.page,
                paginate.page_size,
                paginate.search
            )
                .then((res: any) => {
                    this.loading = false;
                    this.priseEnCharges = res.data.data;
                })
                .catch((res: any) => {
                    console.log('error', res.data);
                });
        },

        async getUniqueBonPriseEnCharge(uuid: string) {
            this.loading = true;
            await PriseEnCharge.getBonPriseEnCharge(uuid)
                .then((res: any) => {
                    this.loading = false;
                    this.priseEnCharge = res.data.data;
                })
                .catch((res: any) => {
                    console.log('error', res);
                });
        },

        async getAllAnalyseMedicalPatient(patient_id: number) {
            // console.log(patient_id);
            await PriseEnCharge.getExamenAnalysesPatient(patient_id)
                .then((res: any) => {
                    this.analyseMedicals = res.data.data;
                })
                .catch((res: any) => {
                    console.log('error', res);
                });
        },

        async getAllTypesTeleconsultations(type: string) {
            await PriseEnCharge.getTypesTeleconsultations(type)
                .then((res: any) => {
                    this.typesTeleconsultation = res.data;
                    // console.log(this.typesTeleconsultation);
                })
                .catch((res: any) => {
                    console.log('error', res);
                });
        },

        async getAllTeleconsultations(patient_id: number) {
            await PriseEnCharge.getTeleconsultationsPatient(patient_id)
                .then((res: any) => {
                    console.log(
                        'res.data.data.data ',
                        res.data.data.data.map((item: any) => ({
                            id: item.id,
                            description: item.motifs[0].description,
                        }))
                    );
                    this.teleconsultationPatient = res.data.data.data.map(
                        (item: any) => ({
                            id: item.id,
                            description: item.motifs[0].description,
                        })
                    );
                    // console.log(this.teleconsultationPatient);
                })
                .catch((res: any) => {
                    console.log('error', res);
                });
        },
        //

        async shareBonPriseEnCharge(uuid: string, priseEnCharge: any) {
            this.loading = true;
            await PriseEnCharge.shareBonPriseEnCharge(uuid, priseEnCharge)
                .then((res: any) => {
                    this.loading = false;
                    this.$toast.success(
                        'Bon de prise en Charge envoyé avec succès',
                        {
                            position: 'top-right',
                        }
                    );
                    /* this.priseEnCharge = res.data.data
                    router.push({name: 'bons-de-prises-en-charge-affichage', params: {uuid: res.data.data?.uuid}}) */
                })
                .catch((err: any) => console.log(err));
        },

        async createNewBonPriseEnCharge(priseEnCharge: any) {
            // let ExamenStorage = JSON.parse(localStorage.getItem('examen') || '[]')
            // let ExamenId = ExamenStorage.map((item: any) => item.id)
            console.log(
                priseEnCharge.teleconsultation_id,
                priseEnCharge.typesTc
            );
            const priseEnCharges =
                priseEnCharge.teleconsultation_id.id != null
                    ? {
                          creator: priseEnCharge.creator,
                          examens_analyses: priseEnCharge.examens_analyses.id,
                          ordonnances: priseEnCharge.ordonnances.id,
                          examens_imageries: priseEnCharge.examen_imageries.id,
                          ligne_temps_id: priseEnCharge.evenement_medical.id.id,
                          niveau_urgence_id: priseEnCharge.niveau_urgence.id.id,
                          option_financement_id:
                              priseEnCharge.option_financement.id,
                          plainte: priseEnCharge.description.id,
                          motifRdv: priseEnCharge.motif.id,
                          date: priseEnCharge.date.id,
                          type_teleconsultation_id: priseEnCharge.typesTc.id.id,
                          teleconsultation_id:
                              priseEnCharge.teleconsultation_id.id.id,
                          medecin_id: priseEnCharge.medecin_id,
                          patient_id: priseEnCharge.patient_id,
                          raison_prescription_id:
                              priseEnCharge.raison_prescription.id.id,
                      }
                    : {
                          creator: priseEnCharge.creator,
                          examens_analyses: priseEnCharge.examens_analyses.id,
                          ordonnances: priseEnCharge.ordonnances.id,
                          examens_imageries: priseEnCharge.examen_imageries.id,
                          ligne_temps_id: priseEnCharge.evenement_medical.id.id,
                          niveau_urgence_id: priseEnCharge.niveau_urgence.id.id,
                          option_financement_id:
                              priseEnCharge.option_financement.id,
                          plainte: priseEnCharge.description.id,
                          motifRdv: priseEnCharge.motif.id,
                          date: priseEnCharge.date.id,
                          type_teleconsultation_id: priseEnCharge.typesTc.id.id,
                          medecin_id: priseEnCharge.medecin_id,
                          patient_id: priseEnCharge.patient_id,
                          raison_prescription_id:
                              priseEnCharge.raison_prescription.id.id,
                      };
            this.loading = true;
            console.log(priseEnCharges);
            await PriseEnCharge.createBonPriseEnCharge(priseEnCharges)
                .then((res: any) => {
                    // this.loading = false
                    this.priseEnCharges = res.data.data;
                    this.$toast.success(
                        'Bon de prise en Charge créé avec succès',
                        {
                            position: 'top-right',
                        }
                    );
                    router.push({
                        name: 'bons-de-prises-en-charge-affichage',
                        params: { uuid: res.data.data?.uuid },
                    });
                })
                .catch((err: any) => {
                    this.loading = false;
                    this.$toast.error(
                        'Une erreur est survenue, Veuillez réesseyer',
                        {
                            position: 'top-right',
                        }
                    );
                    console.log(err);
                });
        },

        async updateExistingBonPriseEnCharge(uuid: string, priseEnCharge: any) {
            // let ExamenStorage = JSON.parse(localStorage.getItem('examen') || '[]')
            // let ExamenId = ExamenStorage.map((item: any) => item.id)
            const priseEnChargesModif = {
                creator: priseEnCharge.creator,
                examens_analyses: priseEnCharge.examens_analyses.id,
                ordonnances: priseEnCharge.ordonnances.id,
                examens_imageries: priseEnCharge.examen_imageries.id,
                ligne_temps_id: priseEnCharge.evenement_medical.id.id,
                niveau_urgence_id: priseEnCharge.niveau_urgence.id.id,
                option_financement_id: priseEnCharge.option_financement.id,
                plainte: priseEnCharge.description.id,
                motifRdv: priseEnCharge.motif.id,
                date: priseEnCharge.date.id,
                type_teleconsultation_id: priseEnCharge.typesTc.id.id,
                teleconsultation_id: priseEnCharge.teleconsultation_id.id.id,
                medecin_id: priseEnCharge.medecin_id,
                patient_id: priseEnCharge.patient_id,
                raison_prescription_id: priseEnCharge.raison_prescription.id.id,
            };

            await PriseEnCharge.updateBonPriseEnCharge(
                uuid,
                priseEnChargesModif
            )
                .then((res: any) => {
                    this.priseEnCharge = res.data.data;
                    this.$toast.success(
                        'Bon de prise en Charge modifié avec succès',
                        {
                            position: 'top-right',
                        }
                    );
                    router.push({
                        name: 'bons-de-prises-en-charge-affichage',
                        params: { uuid: res.data.data?.uuid },
                    });
                    // window.location.href = '/bulletin-examen'
                })
                .catch((err: any) => console.log(err));
        },

        async deleteBon(id: any) {
            this.loading = true;
            await PriseEnCharge.deleteBonPriseEnCharge(id)
                .then((res: any) => {
                    this.loading = false;
                    this.$toast.warning(
                        'Bon de prise en Charge supprimé avec succès',
                        {
                            position: 'top-right',
                        }
                    );
                })
                .catch((err: any) => {
                    this.$toast.error(
                        'Une erreur est survenue, Veuillez réesseyer',
                        {
                            position: 'top-right',
                        }
                    );
                    console.log(err);
                });
        },
    },
});
