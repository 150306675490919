<template lang="">
    <div class="row">
        <div class="col-md-12">
            <CreateAntecedent
                :id="teleconsultation.patient?.dossier.id"
                :teleconsultation_id="teleconsultation.id"
            />
            <div class="table-responsive">
                <table class="table caption-top">
                    <caption>
                        Liste des antécédents
                    </caption>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Type</th>
                            <th>Description</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <div
                        class="position-absolute top-50 start-50"
                        v-if="loader"
                    >
                        <img
                            src="@/assets/icons/Spin.svg"
                            alt=""
                            class="btn-spin"
                        />
                    </div>
                    <tbody v-if="!loader">
                        <tr
                            v-for="(
                                antecedent, index
                            ) in teleconsultation.antededents"
                            :key="`antecedent${index}`"
                        >
                            <td>{{ index + 1 }}</td>
                            <td>{{ antecedent.type?.libelle }}</td>
                            <td v-html="antecedent.description"></td>
                            <td class="d-flex">
                                <span
                                    class="rounded-circle border p-2 cursor-pointer btn-delete"
                                    @click="deleteAntecedant(antecedent.id)"
                                    ><i class="fa-solid fa-trash red"></i
                                ></span>
                            </td>
                            <!-- <Modal v-model="modalDelete" title="Confirmer la suppression" bgcolor="#172b4d" colorTitle="white"  width="md">
                                <div class="d-flex justify-content-center align-items-center gap-1">
                                    <button class="btn bg-default text-white" @click="modalDelete = false">Annuler</button>
                                    <button class="btn btn-danger" @click="deleteAntecedant(antecedent.id)">Supprimer</button>
                                </div>
                            </Modal> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script setup>
import Modal from './../Modal.vue';
import { ref, toRefs, computed, onMounted, inject } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import CreateAntecedent from './CreateAntecedent.vue';

const store = useStore();

const props = defineProps({
    teleconsultation: {},
});

const { teleconsultation } = toRefs(props);
console.log(teleconsultation.value.patient_id);
const antecedant_id = computed(() => {
    return store.state.patient.antecedants?.data.data.id;
});

// const modalDelete = ref(false);

// const ViewModal = () => {
//     modalDelete.value = true;
// }

const swal = inject('$swal');
const deleteAntecedant = (antecedent_id) => {
    swal.fire({
        title: 'Voulez-vous vraiment supprimer cette Antécedent ?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Annuler',
        denyButtonText: `Supprimer`,
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isDenied) {
            store
                .dispatch(
                    'patient/deleteAntecedent',
                    (antecedent_id = antecedent_id)
                )
                .then(() => {
                    // swal.fire('Saved!', '', 'success');
                });
        } else if (result.isConfirmed) {
            swal.fire('Changes are not saved', '', 'info');
        }
    });
};
const loader = computed(() => store.state.teleconsultations.loadingStatus);

const antecedentPatient = computed(
    () => store.state.teleconsultations.antecedents
);
console.log(store.state.teleconsultations);
onMounted(() => {
    store.dispatch(
        'teleconsultations/fetchAntecedents',
        teleconsultation.value.patient_id
    );
});
</script>
