<template lang="">
    <div>
        <div>
            <button
                type="button"
                @click="viewExamen"
                class="btn border d-flex align-items-center gap-1 m-2"
            >
                <i class="fa-solid fa-plus"></i> Ajouter
            </button>
            <Modal
                v-model="newExam"
                title="Nouveau Examen Clinique"
                bgcolor="#172b4d"
                colorTitle="white"
                width="lg"
            >
                <div class="my-3 d-flex justify-content-center" v-if="loader">
                    <img
                        src="@/assets/icons/Spin.svg"
                        alt=""
                        class="btn-spin"
                    />
                </div>

                <div v-if="!loader">
                    <Examen-Clinique-Form :state="state" :v$="v$" />
                    <button
                        class="btn btn-primary"
                        @click="createExamenClinique"
                    >
                        Enregistrer
                    </button>
                </div>
            </Modal>
        </div>
    </div>
</template>
<script setup>
import ExamenCliniqueForm from './ExamenCliniqueForm.vue';
import Modal from './../Modal.vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { ref, reactive, computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const props = defineProps({
    teleconsultation_id: {
        type: Number,
        required: true,
    },
});

const newExam = ref(false);

const rules = computed(() => {
    return {
        description: { required },
    };
});

const state = reactive({
    description: [],
    teleconsultation_id: props.teleconsultation_id,
});

const v$ = useVuelidate(rules, state);

const viewExamen = () => {
    newExam.value = true;
};

const createExamenClinique = () => {
    console.log(state);
    v$.value.$validate();
    if (!v$.value.$error) {
        store
            .dispatch('examen_clinics/createdExamenClinic', {
                fr_description: state.description.map((item) => item.id),
                teleconsultation_id: props.teleconsultation_id,
            })
            .then(() => {
                newExam.value = false;
                state.description = [];
            });
    }
};

const loader = computed(() => store.state.examen_clinics.loadingStatus);
</script>
