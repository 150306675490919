<template lang="">
    <div>
        <router-view />
    </div>
</template>
<script>
export default {
    name: 'eprescription-layout',
};
</script>
