import Axios from '../api';

class NiveauUrgenceService {
    path: string;

    constructor() {
        this.path = '/niveau_urgences';
    }

    async getNiveau_urgences(): Promise<[] | any> {
        return await Axios.get(this.path);
    }
}

export default new NiveauUrgenceService();
