import Axios from '../api';

class PrescriptionService {
    path: string;
    urlPath: string;
    urlFormeMedicamenteuses: string;
    urlConditionnements: string;
    urlCategorieMedicamenteuses: string;
    urlVoieAdministration: string;
    urlUnitePresentations: string;
    urlHoraireDePrises: string;
    urlRelationAlimentaires: string;
    // unite_presentations

    constructor() {
        this.urlPath = '/raison_prescriptions';
        this.path = '/prescriptions';
        this.urlFormeMedicamenteuses =
            '/forme_medicamenteuses?categorie_medicamenteuse_id';
        this.urlConditionnements = '/conditionnements';
        this.urlCategorieMedicamenteuses = '/categorie_medicamenteuses';
        this.urlVoieAdministration = '/voie_administrations';
        this.urlUnitePresentations = '/unite_presentations';
        this.urlHoraireDePrises = '/horaire_de_prises';
        this.urlRelationAlimentaires = '/relation_alimentaires';
    }

    async getRelationAlimentaires(): Promise<any> {
        return await Axios.get(`${this.urlRelationAlimentaires}`);
    }

    async getRaisonPrescriptions(): Promise<any> {
        return await Axios.get(`${this.urlPath}`);
    }

    async getFormeMedicamenteuses(id: number): Promise<any> {
        return await Axios.get(`${this.urlFormeMedicamenteuses}=${id}`);
    }

    async getConditionnements(page = 1, page_size = 5): Promise<any> {
        return await Axios.get(
            `${this.urlConditionnements}?page_size=${page_size}&page=${page}`
        );
    }

    async getCategorieMedicamenteuses(page = 1, page_size = 5): Promise<any> {
        return await Axios.get(
            `${this.urlCategorieMedicamenteuses}?page_size=${page_size}&page=${page}`
        );
    }

    async getVoieAdministration(): Promise<any> {
        return await Axios.get(`${this.urlVoieAdministration}`);
    }

    async getUnitePresentations(): Promise<any> {
        return await Axios.get(`${this.urlUnitePresentations}`);
    }

    async getHoraireDePrises(): Promise<any> {
        return await Axios.get(`${this.urlHoraireDePrises}`);
    }

    async getPrescriptions(page = 1, page_size = 5, search = ''): Promise<any> {
        return await Axios.get(
            `${this.path}?page_size=${page_size}&page=${page}&search=${search}`
        );
    }

    async getPrescription(uuid: number): Promise<any> {
        return await Axios.get(`${this.path}/${uuid}`);
    }

    async createPrescription(data: any): Promise<any> {
        return await Axios.post(this.path, data);
    }
    async deletePrescription(id: any): Promise<any> {
        return await Axios.delete(`${this.path}/${id}`);
    }

    async updatePrescription(uuid: any, data: any): Promise<any> {
        return await Axios.patch(`${this.path}/${uuid}`, data);
    }

    async printPrescription(prescription_id: number): Promise<any> {
        return await Axios.get(`${this.path}/print/${prescription_id}`);
    }
}

export default new PrescriptionService();
