<template lang="">
    <div>
        <user-info />
        <router-view class="mt-4" />
    </div>
</template>
<script>
export default {
    components: {},
};
</script>
