<template lang="">
    <div>
        <button
            type="button"
            @click="newOrdonance = true"
            class="btn border d-flex align-items-center gap-1 m-2"
        >
            <i class="fa-solid fa-plus"></i> Ajouter
        </button>
        <Modal
            v-model="newOrdonance"
            title="Nouvelle Ordonnance"
            bgcolor="#172b4d"
            colorTitle="white"
            width="lg"
        >
            <div class="my-3 d-flex justify-content-center" v-if="loader">
                <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin" />
            </div>

            <div v-if="!loader">
                <Form-Ordonance :state="state" :v$="v$" />
                <button class="btn btn-primary" @click="CreateOrdonance">
                    Enregistrer
                </button>
            </div>
        </Modal>
    </div>
</template>
<script setup>
import FormOrdonance from './FormOrdonance.vue';
import Modal from './../Modal.vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { ref, reactive, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
    teleconsultation_id: {
        type: Number,
        required: true,
    },
});

const store = useStore();

const rules = computed(() => {
    return {
        description: { required },
        date: { required },
    };
});

const state = reactive({
    description: '',
    date: '',
    teleconsultation_id: props.teleconsultation_id,
});
const v$ = useVuelidate(rules, state);

const newOrdonance = ref(false);

const CreateOrdonance = () => {
    v$.value.$validate();
    if (!v$.value.$error) {
        store.dispatch('ordonnances/createdOrdannance', state).then((res) => {
            (state.description = ''), (newOrdonance.value = false);
        });
    }
};

const loader = computed(() => store.state.ordonnances.loadingStatus);
</script>
