import Axios from '../api';
import { Pagination } from '@/_types/pagination';
import { Alerte } from '@/_types/alerte';

class alerteService {
    path: string;
    statuts: string;
    constructor() {
        this.path = '/alertes';
        this.statuts = '/statuts';
    }

    async getAlertes(
        page = 1,
        page_size = 10,
        search = '',
        statut_id = ''
    ): Promise<Pagination> {
        return await Axios.get(
            `${this.path}?page_size=${page_size}&page=${page}&search=${search}&statut_id=${statut_id}`
        );
    }

    async getAlerte(id: number): Promise<Alerte> {
        return await Axios.get(`${this.path}/${id}`);
    }

    async createdAlerte(alerte: any): Promise<Alerte> {
        return await Axios.post(this.path, alerte);
    }

    async updatedAlerte(id: number, alerte: Alerte): Promise<Alerte> {
        return await Axios.patch(`${this.path}/${id}`, alerte);
    }

    async deletedAlerte(id: number): Promise<Alerte> {
        return await Axios.delete(`${this.path}/${id}`);
    }

    async getStatuts(): Promise<Pagination> {
        return await Axios.get(`${this.statuts}`);
    }
}

export default new alerteService();
