<template lang="">
    <div class="default">
        <div class="mb-3">
            <label class="typo__label"
                >Date début<span class="red">*</span></label
            >
            <Datepicker
                auto-apply
                :close-on-auto-apply="false"
                :min-date="new Date()"
                locale="fr"
                :is-24="true"
                v-model="state.date"
                placeholder="date et heure rendez-vous"
            />
            <li v-if="v$.date.$error" class="red list-none">
                {{ 'entrer une date' }}
            </li>
        </div>
        <div class="mb-3">
            <label class="typo__label"
                >Description<span class="red">*</span></label
            >
            <Tiny :height="300" :state="state" :v$="v$" />
        </div>
    </div>
</template>
<script setup>
import VueMultiselect from 'vue-multiselect';
import Datepicker from '@vuepic/vue-datepicker';
import Tiny from '@/components/organisms/Tiny.vue';

import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
    state: {},
    v$: {},
});

const store = useStore();
const newAnt = ref(false);

const height = ref(200);
</script>
