import Axios from '../api';
import { Pagination } from '@/_types/pagination';

class PriseEnCharge {
    path: string;
    path2: string;
    examen_analyses: string;
    teleconsultations: string;

    constructor() {
        this.path = '/bon_prises_en_charges';
        this.path2 = '/types';
        this.examen_analyses = '/examen_analyses/patient';
        this.teleconsultations = '/teleconsultations/patient';
    }

    async getBonPriseEnCharges(
        page = 1,
        page_size = 10,
        search = ''
    ): Promise<Pagination> {
        return await Axios.get(
            `${this.path}?page_size=${page_size}&page=${page}&search=${search}`
        );
    }

    async getBonPriseEnCharge(uuid: string): Promise<PriseEnCharge> {
        return await Axios.get(`${this.path}/${uuid}`);
    }

    async getExamenAnalysesPatient(patient_id: number): Promise<PriseEnCharge> {
        return await Axios.get(
            `${this.examen_analyses}/${patient_id}/informations`
        );
    }

    async getTypesTeleconsultations(type: string | null): Promise<Pagination> {
        return await Axios.get(`${this.path2}?type=${type}`);
    }

    async getTeleconsultationsPatient(
        patient_id: number | null
    ): Promise<Pagination> {
        return await Axios.get(`${this.teleconsultations}/${patient_id}`);
    }

    async createBonPriseEnCharge(data: any): Promise<any> {
        return await Axios.post(this.path, data);
    }

    async shareBonPriseEnCharge(uuid: any, data: any): Promise<any> {
        return await Axios.post(`${this.path}/emails/${uuid}`, data);
    }

    async updateBonPriseEnCharge(uuid: any, data: any): Promise<any> {
        return await Axios.patch(`${this.path}/${uuid}`, data);
    }

    async printBonPriseEnCharge(bulletin_id: number): Promise<any> {
        return await Axios.get(`${this.path}/print/${bulletin_id}`);
    }

    async deleteBonPriseEnCharge(id: any): Promise<any> {
        return await Axios.delete(`${this.path}/${id}`);
    }
}

export default new PriseEnCharge();
