<template lang="">
    <Transition name="bounce">
        <teleport to="body">
            <div
                class="token shadow rounded bg-white px-3 py-4 text-center d-none"
            >
                <i class="fa-solid fa-circle-info fa-2x red"></i><br /><br />
                <li class="list-none fwb default text-uppercase mb-3">
                    Vous serez déconnecté dans 10 minuntes
                </li>
                <button class="btn btn-primary">Cliquez pour continuer</button>
            </div>
        </teleport>
    </Transition>
</template>
<script setup></script>
