<template lang="">
    <div class="layout px-2 px-md-4">
        <router-view />
    </div>
</template>
<script>
export default {
    name: 'Layout',
};
</script>
