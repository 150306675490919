<template lang="">
    <div class="container default">
        <div class="">
            <div
                class="position-absolute top-50 start-50"
                v-if="!typesTeleconsultation"
            >
                <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin" />
            </div>
            <div class="card shadow border-0 px-2 px-md-5 py-4">
                <div class="container-fluid border-bottom-dashed py-2">
                    <div
                        class="form_tc d-md-flex w-100 default mb-3"
                        :class="[
                            alerte
                                ? 'justify-content-between'
                                : ' justify-content-end',
                        ]"
                    >
                        <h2 class="fwb" v-if="alerte">
                            <img
                                src="@/assets/img/alerte-active.png"
                                alt=""
                                class="img-alert"
                            />Informations sur l'alerte
                        </h2>
                        <div
                            v-if="patient_id"
                            class="d-flex gap-1 align-items-center"
                        >
                            <li
                                class="list-none cursor-pointer primary"
                                v-if="urlPatient"
                                @click="CopyLink(urlPatient)"
                            >
                                <i class="fa-solid fa-link mx-1"></i
                                ><span>lien de l'appel</span>
                            </li>
                            <a
                                href="#"
                                @click="OpenMetting()"
                                class="circle-btn bg-primary btn b-video shadow text-white"
                                ><i class="fa fa-video-camera"></i
                            ></a>
                            <!-- <a href="#" v-if="urlPatient != ''" @click="OpenCall(urlPatient)" class="circle-btn btn bg-default shadow" variant="primary" size="sm"><i class="text-white fa fa-phone"></i> </a> -->
                        </div>
                        <!-- <div class="d-flex gap-1 align-items-center">
                            <a href="#" @click="createdMeeting(patient_id)"  class="circle-btn bg-primary btn b-video shadow text-white"><i class="fa fa-video-camera"></i></a>
                            <a href="#" v-if="urlPatient != ''" @click="OpenCall(urlPatient)" class="circle-btn btn bg-default shadow" variant="primary" size="sm"><i class="text-white fa fa-phone"></i> </a>
                        </div> -->
                    </div>

                    <!-- baniere pour si le patient a une alerte -------------------------->
                    <div class="info-alert py-3 mb-3" v-if="alerte">
                        <ul
                            class="p-0 m-0 list-none d-md-flex justify-content-between px-4 align-items-center"
                        >
                            <li class="m-0 mb-3 mb-md-0">
                                <p class="mb-1 default fwb">Alerte par:</p>
                                <p class="mb-1">
                                    {{ patient.nom }} {{ patient.prenom }}
                                </p>
                            </li>
                            <li class="m-0 mb-3 mb-md-0">
                                <p class="mb-1 default fwb">Date et heure:</p>
                                <p class="mb-1">{{ alerte.created_at }}</p>
                            </li>
                            <li class="m-0 mb-3 mb-md-0">
                                <p class="mb-1 default fwb">Status:</p>
                                <p
                                    class="mb-1 status encours w-content"
                                    :class="[
                                        alerte.statut?.valeur == 'En cours'
                                            ? 'encours'
                                            : 'termine' ||
                                              alerte.statut?.valeur ==
                                                  'En attente'
                                            ? 'enattente'
                                            : 'termine',
                                    ]"
                                >
                                    {{ alerte.statut?.valeur }}
                                </p>
                            </li>
                            <li class="m-0 default">
                                <button
                                    class="btn border border-default default"
                                    @click="dialogVisible = true"
                                >
                                    <i class="fa-solid fa-file mx-1"></i>Détails
                                </button>
                            </li>
                        </ul>
                        <!-- <Modal v-model="dialogVisible" title="Affichage de l'alerte" bgcolor="#172b4d" colorTitle="white" notification="true" width="lg">
                            <Alert-Detail v-if="alerte" :alerte="alerte"/>
                            <template #footer>
                                <div>
                                    <div class="clipboard d-flex">
                                        <input disabled class="form-control py-0" type="text" :value="urlPatient">
                                        <button @click="CopyLink(urlPatient)" class="btn btn-default d-flex align-items-center"><i class="fa-solid fa-link mx-1"></i>copier</button>
                                    </div><br>
                                    <div class="d-flex gap-1 align-items-center justify-content-center">
                                        <a href="#" v-if="urlPatient != ''" @click="OpenCall(urlPatient)" class="circle-button btn bg-primary shadow" variant="primary" size="sm"><i class="text-white fa fa-phone fa-2x"></i> </a>
                                        <a href="#" v-if="urlModerator != ''" @click="OpenMetting()"  class="circle-button btn bg-default b-video shadow text-white"><i class="fa fa-video-camera fa-2x"></i></a>
                                    </div>
                                </div>
                            </template>
                        </Modal> -->
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="form-group mb-4 col-md">
                                <label class="typo__label default"
                                    >Type de téléconsultation<span
                                        class="text-danger font-weight-bold"
                                        >*</span
                                    ></label
                                >
                                <div
                                    class="w-100 d-block d-md-flex align-items-center"
                                >
                                    <VueMultiselect
                                        v-if="typesTeleconsultation"
                                        v-model="state.typesTc"
                                        :multiple="false"
                                        :close-on-select="true"
                                        :options="typesTeleconsultation"
                                        :preserve-search="true"
                                        placeholder="Type Téléconsultation"
                                        label="libelle"
                                        track-by="id"
                                    >
                                    </VueMultiselect>
                                </div>
                                <li
                                    v-if="v$.$error && v$.typesTc.$invalid"
                                    class="red list-none"
                                >
                                    {{ 'veillez sélectionner un type' }} <br />
                                </li>
                            </div>
                            <div class="form-group mb-4 col-md">
                                <label class="typo__label default"
                                    >Etablissement de consultation
                                    <span class="text-danger font-weight-bold"
                                        >*</span
                                    ></label
                                >
                                <div
                                    class="w-100 d-block d-md-flex align-items-center"
                                >
                                    <VueMultiselect
                                        v-if="typesTeleconsultation"
                                        v-model="state.etablissement"
                                        :options="dataEtablissementMap"
                                        :close-on-select="true"
                                        :preserve-search="true"
                                        placeholder="Rechercher l'établissement de consultation"
                                        label="name"
                                        track-by="id"
                                        :loading="state.isLoadingEtablissement"
                                        @search-change="asyncFindEtablissement"
                                    ></VueMultiselect>
                                </div>
                                <li
                                    v-if="
                                        v$.$error && v$.etablissement.$invalid
                                    "
                                    class="red list-none"
                                >
                                    {{ "L'établissement est obligatoire" }}
                                    <br />
                                </li>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="py-2">
                        <div class="pt-2">
                            <h2 class="px-2 fwb">
                                <img
                                    src="@/assets/icons/teleconsultation-active.png"
                                    alt=""
                                    class="img-alert"
                                />Téléconsultation de
                                <span class="font-weight-bold px-1">{{
                                    patient.nom
                                }}</span>
                            </h2>
                        </div>
                        <!-- <div class="border dashed px-2 rounded">
                            <h2 class="text-center">paramètres vitaux</h2>
                            <div></div>
                        </div> -->
                    </div>

                    <form class="py-4">
                        <div class="row">
                            <!-- gaps-5 -->
                            <div
                                :class="[
                                    checkForm ? 'col' : 'col d-md-block d-none',
                                ]"
                                class="px-0 px-md-2"
                            >
                                <div class="container-fluid w-100 px-md-3">
                                    <div class="form-group mb-4">
                                        <label class="typo__label default"
                                            >Motif principal
                                            <span
                                                class="text-danger font-weight-bold"
                                                >*</span
                                            ></label
                                        >
                                        <div
                                            class="w-100 d-block d-md-flex align-items-center"
                                        >
                                            <VueMultiselect
                                                v-if="typesTeleconsultation"
                                                v-model="state.motifs"
                                                tag-placeholder="Ajouter un motif"
                                                :close-on-select="true"
                                                :options="dataMotifMap"
                                                :preserve-search="true"
                                                placeholder="Rechercher ou ajouter un motif"
                                                label="description"
                                                track-by="id"
                                                :taggable="true"
                                                @tag="addMotif"
                                                :loading="
                                                    state.isLoadingMotifPrincipale
                                                "
                                                @search-change="
                                                    asyncFindMotifPrincipale
                                                "
                                            ></VueMultiselect>
                                        </div>
                                        <li
                                            v-if="
                                                v$.$error && v$.motifs.$invalid
                                            "
                                            class="red list-none"
                                        >
                                            {{
                                                'Le motif principal est obligatoire'
                                            }}
                                            <br />
                                        </li>
                                    </div>
                                    <div class="form-group mb-4">
                                        <label class="typo__label default"
                                            >Motifs secondaires
                                            <!-- <span class="text-danger font-weight-bold">*</span> --></label
                                        >
                                        <div
                                            class="w-100 d-block d-md-flex align-items-center"
                                        >
                                            <VueMultiselect
                                                v-if="typesTeleconsultation"
                                                v-model="
                                                    state.motifs_secondaires
                                                "
                                                tag-placeholder="Ajouter un motif"
                                                :multiple="true"
                                                :max="2"
                                                :close-on-select="true"
                                                :options="dataMotifMap"
                                                :preserve-search="true"
                                                placeholder="Rechercher ou ajouter un motif"
                                                label="description"
                                                track-by="id"
                                                :taggable="true"
                                                @tag="addMotifSecondaire"
                                                :loading="
                                                    state.isLoadingMotifSecondaire
                                                "
                                                @search-change="
                                                    asyncFindMotifSecondaire
                                                "
                                            ></VueMultiselect>
                                        </div>
                                    </div>
                                    <div class="form-group mb-4">
                                        <label class="typo__label default"
                                            >Anamnèse
                                            <span
                                                class="text-danger font-weight-bold"
                                                >*</span
                                            ></label
                                        >
                                        <VueMultiselect
                                            v-if="typesTeleconsultation"
                                            v-model="state.anamnese_id"
                                            :close-on-select="true"
                                            :options="DataAnamneseMap"
                                            :preserve-search="true"
                                            @select="AnamneseDescribe"
                                            placeholder="Rechercher l'anamnèse"
                                            label="description"
                                            track-by="id"
                                            :loading="state.isLoadingAnamneses"
                                            @search-change="asyncFindAnamneses"
                                        >
                                        </VueMultiselect>
                                        <li
                                            v-if="
                                                v$.$error &&
                                                v$.anamnese_id.$invalid
                                            "
                                            class="red list-none"
                                        >
                                            {{ "L'anamnèse est obligatoire" }}
                                            <br />
                                        </li>
                                        <div
                                            class="form-group mt-4"
                                            v-if="describe"
                                        >
                                            <label class="typo__label default"
                                                >Description de l'histoire de la
                                                maladie
                                                <span
                                                    class="text-danger font-weight-bold"
                                                    >*</span
                                                ></label
                                            >
                                            <Editor
                                                api-key="mm3cmjvl9px5ksezprcy72un7g456ii25fpje4frqemgkrqn"
                                                :init="{
                                                    height: 200,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount',
                                                    ],
                                                    toolbar:
                                                        'undo redo | formatselect | bold italic backcolor | \
                                                        alignleft aligncenter alignright alignjustify | \
                                                        bullist numlist outdent indent | removeformat | help',
                                                }"
                                                v-model="state.anamnese"
                                            />
                                            <li
                                                v-if="
                                                    v$.$error &&
                                                    v$.anamnese.$invalid
                                                "
                                                class="red list-none"
                                            >
                                                {{ 'Entrez votre description' }}
                                                <br />
                                            </li>
                                        </div>
                                    </div>

                                    <!-- allergie module ---------------------------------------------->
                                    <div
                                        class="form-group mb-4 px-4 py-2 rounded"
                                        :class="[
                                            AllergiePatient ? 'gray-color' : '',
                                        ]"
                                    >
                                        <div class="allergie">
                                            <label
                                                class="typo__label default mb-1"
                                                v-if="
                                                    AllergiePatient?.length > 0
                                                "
                                                >Allergies du patient</label
                                            >
                                            <label
                                                class="typo__label default mb-1"
                                                v-else
                                                >Pas d'allergies conçernant ce
                                                patient</label
                                            >
                                            <ul class="list-none p-0">
                                                <li
                                                    v-for="(
                                                        allergieP, id
                                                    ) in AllergiePatient"
                                                    :key="id"
                                                >
                                                    {{ allergieP?.description }}
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="d-flex gap-1">
                                            <!-- <label class="typo__label default">Ajouter Allergie(s)</label> -->
                                            <div
                                                class="w-100 d-block d-md-flex align-items-center"
                                                v-if="docState === 'annuler'"
                                            >
                                                <VueMultiselect
                                                    v-if="typesTeleconsultation"
                                                    v-model="state.allergies"
                                                    tag-placeholder="Ajouter une allergie"
                                                    :multiple="true"
                                                    :close-on-select="true"
                                                    :options="dataAllergieMap"
                                                    :preserve-search="true"
                                                    placeholder="Rechercher ou ajouter une allergie"
                                                    label="description"
                                                    track-by="id"
                                                    :taggable="true"
                                                    @tag="addAllergie"
                                                    :loading="
                                                        state.isLoadingMotifAllergie
                                                    "
                                                    @search-change="
                                                        asyncFindAllergie
                                                    "
                                                ></VueMultiselect>
                                            </div>
                                            <div class="">
                                                <Transition name="slide-up">
                                                    <button
                                                        type="button"
                                                        v-if="
                                                            docState === 'saved'
                                                        "
                                                        @click="
                                                            docState = 'annuler'
                                                        "
                                                        class="btn btn-dark d-flex align-items-center gap-1"
                                                    >
                                                        <i
                                                            class="fa-solid fa-plus"
                                                        ></i
                                                        >Ajouter
                                                    </button>
                                                    <button
                                                        type="button"
                                                        v-else-if="
                                                            docState ===
                                                            'annuler'
                                                        "
                                                        @click="
                                                            (docState =
                                                                'saved'),
                                                                (state.allergies =
                                                                    '')
                                                        "
                                                        class="btn shadow default d-flex align-items-center border border-dark justify-content-center gap-1"
                                                    >
                                                        <i
                                                            class="fa-solid fa-bookmark"
                                                        ></i
                                                        ><span>Annuler</span>
                                                    </button>
                                                </Transition>
                                            </div>
                                        </div>
                                    </div>

                                    <!------ module des antécédents du patient avec formulaire ajout -------->
                                    <div
                                        class="form-group mb-4 px-4 py-2 rounded"
                                        :class="[
                                            antecedentPatient
                                                ? 'gray-color'
                                                : '',
                                        ]"
                                    >
                                        <div
                                            class="allergie"
                                            v-if="AllergiePatient"
                                        >
                                            <label
                                                class="typo__label default mb-1"
                                                >Antécédents du patient</label
                                            >
                                            <ul class="list-none p-0">
                                                <li
                                                    :id="`popover-target-${antecedentP.uuid}`"
                                                    v-for="(
                                                        antecedentP, id
                                                    ) in antecedentPatient"
                                                    :key="id"
                                                >
                                                    {{
                                                        antecedentP.type
                                                            ?.libelle
                                                            ? antecedentP.type
                                                                  ?.libelle
                                                            : antecedentP.type
                                                    }}
                                                    <b-popover
                                                        :target="`popover-target-${antecedentP.uuid}`"
                                                        triggers="hover"
                                                        placement="top"
                                                    >
                                                        <p
                                                            v-html="
                                                                antecedentP?.description
                                                            "
                                                            class="m-0"
                                                        ></p>
                                                    </b-popover>
                                                </li>
                                                <li
                                                    :id="`popover-target-${item.id}`"
                                                    v-for="(
                                                        item, id
                                                    ) in dataAntecedent"
                                                    :key="id"
                                                >
                                                    {{ item.type.libelle }}
                                                    <i
                                                        @click="
                                                            removeAntecedent(
                                                                item.type
                                                            )
                                                        "
                                                        class="fa-solid fa-xmark cursor-pointer red"
                                                    ></i>
                                                    <b-popover
                                                        :target="`popover-target-${item.id}`"
                                                        triggers="hover"
                                                        placement="top"
                                                    >
                                                        <p
                                                            v-html="
                                                                item?.description
                                                            "
                                                            class="m-0"
                                                        ></p>
                                                    </b-popover>
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            <button
                                                @click="
                                                    antecedentVisible = true
                                                "
                                                type="button"
                                                class="btn btn-dark"
                                            >
                                                <i
                                                    class="fa-solid fa-plus mx-1"
                                                ></i
                                                >Ajouter
                                            </button>
                                        </div>
                                        <Modal
                                            v-model="antecedentVisible"
                                            title="Nouveau antécédent"
                                            bgcolor="#172b4d"
                                            colorTitle="white"
                                            notification="true"
                                            width="md"
                                        >
                                            <div>
                                                <div v-if="typesAntecedent">
                                                    <label class="typo__label"
                                                        >Type d'antécédent<span
                                                            class="red"
                                                            >*</span
                                                        ></label
                                                    >
                                                    <VueMultiselect
                                                        v-model="
                                                            AntecedentState.type
                                                        "
                                                        deselect-label="Can't remove this value"
                                                        track-by="id"
                                                        label="libelle"
                                                        placeholder="Sélectionneer le type"
                                                        :options="
                                                            typesAntecedent
                                                        "
                                                        :searchable="false"
                                                        :allow-empty="false"
                                                    >
                                                        <template
                                                            v-slot:singleLabel="{
                                                                option,
                                                            }"
                                                            ><strong>{{
                                                                option.type
                                                            }}</strong></template
                                                        >
                                                    </VueMultiselect>
                                                    <li
                                                        v-if="A$.type.$error"
                                                        class="red list-none"
                                                    >
                                                        {{
                                                            "sélectionner un type d'antécédent"
                                                        }}
                                                    </li>
                                                </div>
                                                <div class="mt-3">
                                                    <label class="typo__label"
                                                        >Date début<span
                                                            class="red"
                                                            >*</span
                                                        ></label
                                                    >
                                                    <Datepicker
                                                        auto-apply
                                                        :close-on-auto-apply="
                                                            false
                                                        "
                                                        locale="fr"
                                                        :max-date="new Date()"
                                                        :is-24="true"
                                                        v-model="
                                                            AntecedentState.date
                                                        "
                                                        placeholder="sélectionner une date"
                                                    />
                                                    <li
                                                        v-if="A$.date.$error"
                                                        class="red list-none"
                                                    >
                                                        {{ 'entrer une date' }}
                                                    </li>
                                                </div>
                                                <div class="mt-3">
                                                    <label class="typo__label"
                                                        >Description<span
                                                            class="red"
                                                            >*</span
                                                        ></label
                                                    >
                                                    <Tiny
                                                        :height="300"
                                                        :state="AntecedentState"
                                                        :v$="A$"
                                                    />
                                                </div>
                                            </div>
                                            <div class="mt-3">
                                                <button
                                                    @click="SaveAntecedent"
                                                    type="button"
                                                    class="btn btn-primary"
                                                >
                                                    Enregistrer
                                                </button>
                                            </div>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                            <div
                                :class="[
                                    checkForm ? 'col' : 'col d-md-block d-none',
                                ]"
                                class="px-0 px-md-2"
                            >
                                <div class="container-fluid w-100 px-md-3">
                                    <!-- conduit à ténir module ---------------------------------------------->
                                    <div class="form-group mb-4">
                                        <label class="typo__label default"
                                            >Conduite à Ténir (CAT)
                                            <span
                                                class="text-danger font-weight-bold"
                                                >*</span
                                            ></label
                                        >
                                        <Editor
                                            api-key="mm3cmjvl9px5ksezprcy72un7g456ii25fpje4frqemgkrqn"
                                            :init="{
                                                height: 200,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount',
                                                ],
                                                toolbar:
                                                    'undo redo | formatselect | bold italic backcolor | \
                                                    alignleft aligncenter alignright alignjustify | \
                                                    bullist numlist outdent indent | removeformat | help',
                                            }"
                                            v-model="state.cat"
                                        />
                                        <li
                                            v-if="v$.$error && v$.cat.$invalid"
                                            class="red list-none"
                                        >
                                            {{
                                                'renseigner la conduite à ténir par le patient'
                                            }}
                                            <br />
                                        </li>
                                    </div>

                                    <!-- diagnostic module -------------------------------------->
                                    <div class="form-group mb-4 gray-color">
                                        <Icd :maladies="state.maladies" />
                                        <li
                                            v-if="
                                                v$.$error &&
                                                v$.maladies.$invalid
                                            "
                                            class="red list-none px-2"
                                        >
                                            {{
                                                'Sélectionner au moins un diagnostic'
                                            }}
                                            <br />
                                        </li>
                                        <label class="typo__label default px-2"
                                            >Description diagnostic
                                            <span
                                                class="text-danger font-weight-bold"
                                                >*</span
                                            ></label
                                        >
                                        <div class="px-2 pb-2">
                                            <Editor
                                                api-key="mm3cmjvl9px5ksezprcy72un7g456ii25fpje4frqemgkrqn"
                                                :init="{
                                                    height: 200,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount',
                                                    ],
                                                    toolbar:
                                                        'undo redo | formatselect | bold italic backcolor | \
                                                        alignleft aligncenter alignright alignjustify | \
                                                        bullist numlist outdent indent | removeformat | help',
                                                }"
                                                v-model="
                                                    state.description_diagnostic
                                                "
                                            />
                                            <li
                                                v-if="
                                                    v$.$error &&
                                                    v$.description_diagnostic
                                                        .$invalid
                                                "
                                                class="red list-none"
                                            >
                                                {{
                                                    'renseigner la description du dignostic'
                                                }}
                                                <br />
                                            </li>
                                        </div>
                                    </div>

                                    <!------ module date téléconsultation -------->
                                    <div class="form-group mb-4">
                                        <div class="w-100">
                                            <label
                                                for="exampleFormControlSelect1"
                                                class="mb-1 typo__label"
                                                >Date téléconsultation
                                                <span
                                                    class="text-danger font-weight-bold"
                                                    >*</span
                                                ></label
                                            >
                                            <Datepicker
                                                auto-apply
                                                :close-on-auto-apply="false"
                                                locale="fr"
                                                :max-date="new Date()"
                                                :is-24="true"
                                                v-model="state.dateNow"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="d-flex justify-content-center bg-none d-md-none d-block pt-3">

                                        <div :disabled="v$.cat.$invalid || v$.etablissement.$invalid || v$.motifs.$invalid" class="btn btn-primary w-100 pe-none px-3 shadow" @click="checkMethod">
                                           Suivant
                                        </div>
                                    </div> -->
                            </div>
                        </div>

                        <div class="container-fluid">
                            <div class="form-check mb-2">
                                <input
                                    v-model="examenClinic_state"
                                    class="form-check-input"
                                    type="checkbox"
                                    id="examenClinic_state"
                                />
                                <label
                                    class="form-check-label"
                                    for="examenClinic_state"
                                >
                                    La consultation donnera-t-elle lieu à des
                                    examens clinique?
                                </label>
                            </div>
                            <div
                                class="form-group mb-4 px-md-4 row"
                                v-if="examenClinic_state"
                            >
                                <div class="col-md-6">
                                    <label class="typo__label default"
                                        >Examen clinique
                                    </label>
                                    <div
                                        class="d-block d-md-flex align-items-center examen-item"
                                    >
                                        <VueMultiselect
                                            v-model="state.examen_clinic"
                                            :options="dataExamenClinicMap"
                                            :multiple="true"
                                            :preserve-search="true"
                                            :close-on-select="true"
                                            placeholder="Rechercher un examen clinique"
                                            label="description"
                                            track-by="id"
                                            :loading="
                                                state.isLoadingexamenClinic
                                            "
                                            @search-change="
                                                asyncFindexamenClinic
                                            "
                                        ></VueMultiselect>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label class="typo__label default"
                                        >Description des examens
                                        cliniques</label
                                    >
                                    <Editor
                                        api-key="mm3cmjvl9px5ksezprcy72un7g456ii25fpje4frqemgkrqn"
                                        :init="{
                                            height: 200,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount',
                                            ],
                                            toolbar:
                                                'undo redo | formatselect | bold italic backcolor | \
                                        alignleft aligncenter alignright alignjustify | \
                                        bullist numlist outdent indent | removeformat | help',
                                        }"
                                        v-model="
                                            state.description_examen_clinique
                                        "
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="container-fluid">
                            <div class="form-check mb-2">
                                <input
                                    v-model="examen_state"
                                    class="form-check-input"
                                    type="checkbox"
                                    id="examen_state"
                                />
                                <label
                                    class="form-check-label"
                                    for="examen_state"
                                >
                                    La consultation donnera-t-elle lieu à des
                                    examens complémentaires?
                                </label>
                            </div>
                            <div
                                class="form-group mb-4 px-md-4 col-md-6"
                                v-if="examen_state"
                            >
                                <label class="typo__label default"
                                    >Examen(s) complementaire(s)</label
                                >
                                <div
                                    class="d-block d-md-flex align-items-center examen-item"
                                >
                                    <VueMultiselect
                                        v-model="state.examens"
                                        tag-placeholder="Ajouter un examen complémentaire"
                                        :multiple="true"
                                        :close-on-select="true"
                                        :options="dataExamenMap"
                                        :preserve-search="true"
                                        placeholder="Rechercher ou ajouter un examen complémentaire"
                                        label="description"
                                        track-by="id"
                                        :taggable="true"
                                        @tag="addExamenComplementaire"
                                        :loading="
                                            state.isLoadingExamenComplementaire
                                        "
                                        @search-change="
                                            asyncFindExamenComplementaire
                                        "
                                    ></VueMultiselect>
                                </div>
                            </div>
                        </div>

                        <div class="container-fluid">
                            <div class="form-check mb-2">
                                <input
                                    v-model="OrdonanceState"
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="OrdonanceState"
                                />
                                <label
                                    class="form-check-label"
                                    for="OrdonanceState"
                                >
                                    Cette consultation aura t'elle une
                                    ordonnance ?
                                </label>
                            </div>

                            <div
                                class="container-fluid px-md-3 row"
                                v-if="OrdonanceState"
                            >
                                <div class="form-group mb-4 col-md-6">
                                    <label class="typo__label default"
                                        >Rédigez les informations d'ordonance
                                        ici</label
                                    >
                                    <div>
                                        <Editor
                                            api-key="mm3cmjvl9px5ksezprcy72un7g456ii25fpje4frqemgkrqn"
                                            :init="{
                                                height: 300,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount',
                                                ],
                                                toolbar:
                                                    'undo redo | formatselect | bold italic backcolor | \
                                                alignleft aligncenter alignright alignjustify | \
                                                bullist numlist outdent indent | removeformat | help',
                                            }"
                                            v-model="
                                                state.ordonnance_description
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="container-fluid">
                            <div class="form-check mb-2">
                                <input
                                    v-model="state.rendezVous"
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="rendez-vous"
                                />
                                <label
                                    class="form-check-label"
                                    for="rendez-vous"
                                >
                                    Cette consultation aura t'elle un
                                    rendez-vous ?
                                </label>
                            </div>
                            <div class="row">
                                <!-- gaps-5 -->
                                <div
                                    :class="[
                                        state.rendezVous
                                            ? 'col-md d-block d-md-block'
                                            : 'col d-none',
                                    ]"
                                    class="px-0 px-md-2"
                                >
                                    <div class="container-fluid px-md-3">
                                        <div class="form-group mb-4">
                                            <label class="typo__label default"
                                                >Motif Rendez-vous
                                                <span
                                                    class="text-danger font-weight-bold"
                                                    >*</span
                                                ></label
                                            >
                                            <div>
                                                <Editor
                                                    api-key="mm3cmjvl9px5ksezprcy72un7g456ii25fpje4frqemgkrqn"
                                                    :init="{
                                                        height: 200,
                                                        menubar: false,
                                                        plugins: [
                                                            'advlist autolink lists link image charmap print preview anchor',
                                                            'searchreplace visualblocks code fullscreen',
                                                            'insertdatetime media table paste code help wordcount',
                                                        ],
                                                        toolbar:
                                                            'undo redo | formatselect | bold italic backcolor | \
                                                        alignleft aligncenter alignright alignjustify | \
                                                        bullist numlist outdent indent | removeformat | help',
                                                    }"
                                                    v-model="state.motif"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    :class="[
                                        state.rendezVous
                                            ? 'col-md d-block d-md-block px-md-3'
                                            : 'col d-none',
                                    ]"
                                    class="mt-3 mt-md-0"
                                >
                                    <div
                                        class="container-fluid w-100 px-0 px-md-3"
                                    >
                                        <div class="form-group mb-4">
                                            <label
                                                for="exampleFormControlSelect1"
                                                class="mb-1 typo__label"
                                                >Date rendez-vous
                                                <span
                                                    class="text-danger font-weight-bold"
                                                    >*</span
                                                ></label
                                            >
                                            <Datepicker
                                                auto-apply
                                                :close-on-auto-apply="false"
                                                locale="fr"
                                                :min-date="new Date()"
                                                :is-24="true"
                                                v-model="state.date"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div
                                class="d-flex justify-content-md-center justify-content-between gap-1 mt-2"
                            >
                                <!-- <span class="btn btn-dark d-block d-md-none "  @click="Form">
                                    Precédent
                                </span> -->
                                <button
                                    @click.prevent="
                                        createdTeleconsultation(state)
                                    "
                                    type="submit"
                                    class="btn btn-primary py-2 px-4 shadow"
                                    v-if="!mloadingStatus"
                                >
                                    Enregistrer
                                </button>
                                <div
                                    class="position-absolute top-50 start-50"
                                    v-else
                                >
                                    <img
                                        src="@/assets/icons/Spin.svg"
                                        alt=""
                                        class="btn-spin"
                                    />
                                </div>
                                <!-- <button v-if="motifValidate" type="button" class="btn btn-desable py-2 px-4 shadow">
                                    Enregistrer
                                </button> -->
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import VueMultiselect from 'vue-multiselect';
import {
    ref,
    defineComponent,
    computed,
    reactive,
    onMounted,
    watchEffect,
    ComputedRef,
} from 'vue';
import UserInfo from '@/components/organisms/UserInfo.vue';
import AddPrescription from '@/views/Admin/ePrescription/AddPrescription.vue';
import Icd from '@/components/organisms/Icd.vue';
import Editor from '@tinymce/tinymce-vue';
import Modal from '@/views/Admin/Teleconsultation/Modal.vue';
import AlertDetail from '@/views/Admin/alertes/AlertDetail.vue';
import AntecedentForm from '@/views/Admin/Teleconsultation/antecedents/AntecedentForm.vue';
import Tiny from '@/components/organisms/Tiny.vue';

import { useClipboard, usePermission } from '@vueuse/core';

import { useVuelidate } from '@vuelidate/core';
import { useTextareaAutosize } from '@vueuse/core';

import { required } from '@vuelidate/validators';
import moment from 'moment';
import LoaderData from '@/components/molecules/LoaderData.vue';

import Datepicker from '@vuepic/vue-datepicker';

import AllergieService from '@/_services/AllergieService';
import { Allergie } from '@/_types/allergie';
//Allergie data

import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import router from '@/router';
import { Pagination } from '@/_types';
import { useToast } from 'vue-toast-notification';
import VuexPersistence from 'vuex-persist';

const route = useRoute();
const store = useStore();
const { text, isSupported, copy } = useClipboard();

const { textarea, input } = useTextareaAutosize();
//
const $toast = useToast();

const props = defineProps({
    id: Number,
});

const examenClinic_state = ref(false);

const OrdonanceState = ref(false);

const docState = ref('saved');
const dialogVisible = ref(false);
const antecedentVisible = ref(false);
const rules = {
    anamnese_id: { required },
    etablissement: { required },
    maladies: { required },
    motifs: { required },
    typesTc: { required },
    cat: { required },
    anamnese: { required },
    description_diagnostic: { required },
};

const AntecedentRules = computed(() => {
    return {
        description: { required },
        type: { required },
        date: { required },
    };
});
let AntecedentState = reactive({
    type: {},
    description: '',
    date: null,
});

const dataAntecedent: [] | any = ref([]);
const A$ = useVuelidate(AntecedentRules, AntecedentState);
const SaveAntecedent = () => {
    A$.value.$validate();
    if (!A$.value.$error) {
        let antecedent = {
            type: AntecedentState.type,
            description: AntecedentState.description,
            date: AntecedentState.date,
        };
        dataAntecedent.value.push(antecedent);
        localStorage.setItem(
            'antecedent',
            JSON.stringify(dataAntecedent.value)
        );
        antecedentVisible.value = false;
    } else {
        console.log(A$);
    }
};

const removeAntecedent = (e: any) => {
    dataAntecedent.value = dataAntecedent.value.filter(
        (item: any) => item.type !== e
    );
};

const checkOptions = ref(false);
const firstPage1 = ref(false);
const examen_state = ref(false);
const RDV = ref(false);
const checkForm = ref(true);
const formButton = ref(true);
const validateButton = ref(false);
const Form = () => (
    (checkOptions.value = false),
    (checkForm.value = true),
    (RDV.value = false),
    (formButton.value = true)
);
const checkMethod = () => (
    (firstPage1.value = true),
    (checkOptions.value = true),
    (checkForm.value = false),
    (RDV.value = false),
    (formButton.value = false)
);

const dataAllergie = computed(() => store.state.allergie.paginations);

const examenData = computed(() => store.state.examen.examen);

const examenCliniques = computed(
    () => store.state.examen_clinics.examen_clinic
);

const anamnese = computed(() => store.state.anamnese.anamnese);

const etablissements = computed(() => store.state.etablissement.etablissements);

const typesTeleconsultation: [] | any = computed(
    () => store.state.types.typesTeleconsultation
);
const typesAntecedent: [] | any = computed(
    () => store.state.types.typesAntecedent
);

const motifs = computed(() => store.state.motif.motifs);

const patient = computed(() => store.state.patient.select.user);

const urlModerator = computed(() => store.state.mettings.urlModerator);
const urlPatient = computed(() => store.state.mettings.urlPatient);

const alerte = computed(() => store.state.teleconsultations.alerte);
const patient_id = computed(() => store.state.patient.select.user?.id);
const dossier_medical_id = computed(
    () => store.state.patient.select.dossier?.id
);
const user_id = computed(() => store.state.users.user.id);

const AllergiePatient = computed(() => store.state.teleconsultations.allergies);
const antecedentPatient = computed(
    () => store.state.teleconsultations.antecedents
);

const mloadingStatus = computed(
    () => store.state.teleconsultations.mloadingStatus
);

const state: any = reactive({
    ordonnance_description: '',
    etablissement: '',
    motifs: [],
    motifs_secondaires: [],
    examens: [],
    examen_clinic: [],
    allergies: [],
    maladies: [],
    typesTc: '',
    cat: '',
    anamnese_id: '',
    motif: '',
    rendezVous: '',
    date: '',
    anamnese: '',
    description_diagnostic: '',
    description_examen_clinique: '',
    dateNow: new Date(),
    creator: user_id,
    patient_id: patient_id,
    dossier_medical_id: dossier_medical_id,
    isLoadingMotifPrincipale: false,
    isLoadingMotifSecondaire: false,
    isLoadingMotifAllergie: false,
    isLoadingExamenComplementaire: false,
    isLoadingEtablissement: false,
    isLoadingexamenClinic: false,
    isLoadingAnamneses: false,
});

const v$ = useVuelidate(rules, state);

const rdv = computed(() => {
    return store.state.teleconsultations.rendez_vous?.map((i: any) => {
        return {
            motifs: i.motifs,
            date: i.date,
        };
    });
});

const anamneseMap = function (anamnese: any) {
    return anamnese?.data.map((item: any) => {
        return {
            id: item.id,
            description: item.fr_description,
        };
    });
};

const DataAnamneseMap: any | [] = computed(() => anamneseMap(anamnese.value));

const etablissementMap = function (etablissements: any) {
    return etablissements?.data.map((item: any) => {
        return {
            id: item.id,
            name: item.name,
        };
    });
};

const dataEtablissementMap: [] | any = computed(() =>
    etablissementMap(etablissements.value)
);

const examensMap = function (examenData: any) {
    return examenData?.data.data.map((item: any) => {
        return {
            id: item.id,
            description: item.fr_description,
        };
    });
};

const dataExamenMap: [] | any = computed(() => examensMap(examenData.value));

const examenCliniqueMap = function (examenCliniques: any) {
    return examenCliniques?.data.data.map((item: any) => {
        return {
            id: item.id,
            description: item.fr_description,
        };
    });
};
const dataExamenClinicMap: [] | any = computed(() =>
    examenCliniqueMap(examenCliniques.value)
);

const allergieMap = function (dataAllergie: Allergie[] | any) {
    return dataAllergie?.data.map((item: Allergie) => {
        return { id: item.id, description: item.description };
    });
};

const dataAllergieMap: [] | any = computed(() => {
    if (dataAllergie.value) {
        return allergieMap(dataAllergie.value);
    }
    searchAlergie(); //
});

const asyncFindMotifPrincipale = (query: string) => {
    state.isLoadingMotifPrincipale = true;
    store.dispatch('motif/searchMotif', { search: query, page: 1 }).then(() => {
        state.isLoadingMotifPrincipale = false;
    });
};

const asyncFindMotifSecondaire = (query: string) => {
    state.isLoadingMotifSecondaire = true;
    store.dispatch('motif/searchMotif', { search: query, page: 1 }).then(() => {
        state.isLoadingMotifSecondaire = false;
    });
};

const asyncFindAllergie = (query: string) => {
    state.isLoadingMotifAllergie = true;
    store
        .dispatch('allergie/searchAllergies', { search: query, page: 1 })
        .then(() => {
            state.isLoadingMotifAllergie = false;
        });
};

const asyncFindExamenComplementaire = (query: string) => {
    state.isLoadingExamenComplementaire = true;
    store
        .dispatch('examen/searchExamens', { search: query, page: 1 })
        .then(() => {
            state.isLoadingExamenComplementaire = false;
        });
};

const asyncFindEtablissement = (query: string) => {
    state.isLoadingEtablissement = true;
    store
        .dispatch('etablissement/searchEtablissements', {
            search: query,
            page: 1,
        })
        .then(() => {
            state.isLoadingEtablissement = false;
        });
};

const asyncFindexamenClinic = (query: string) => {
    state.isLoadingexamenClinic = true;
    store
        .dispatch('examen_clinics/searchExamenClinics', {
            search: query,
            page: 1,
        })
        .then(() => {
            state.isLoadingexamenClinic = false;
        });
};

const asyncFindAnamneses = (query: string) => {
    state.isLoadingAnamneses = true;
    store
        .dispatch('anamnese/searchAnamnese', { search: query, page: 1 })
        .then(() => {
            state.isLoadingAnamneses = false;
        });
};

const MotifMap = function (dataMotif: any) {
    return dataMotif?.data.data.map((item: any) => {
        return {
            id: item.id,
            description: item.description,
        };
    });
};
const dataMotifMap: [] | any = computed(() => MotifMap(motifs.value));

const searchAlergie = async (value?: string) => {
    AllergieService.searchAllergie(value).then((res) => {
        let allergie: {} | null = [];
        if (res.data.data) {
            return (allergie = allergieMap(res.data.data));
        }
    });
};

const OpenMetting = () => {
    store.dispatch('mettings/createMetting', patient_id.value).then(() => {
        store.dispatch('mettings/joinMetting', patient_id.value).then(() => {
            setTimeout(() => {
                window.open(`${urlModerator.value}`, '_blank');
            }, 3000);
        });
    });
};

const OpenCall = (urlPatient: string) => {
    window.open(`${urlPatient}`, '_blank');
    localStorage.setItem('url', urlPatient);
};

const emit = defineEmits(['gotoTeleconsultationForm']);
const linkCreate = ref(false);

if (route.query.slug) {
    linkCreate.value = true;
}

const createdMeeting = (id: 0) => {
    store.dispatch('mettings/createMetting', id).then(() => {
        window.open(`${store.state.mettings.urlModerator}`, '_blank');
        emit('gotoTeleconsultationForm');
    });
    store.dispatch('mettings/joinMetting', id);
};

const addMotif = (newTag: any) => {
    const tag = {
        id: null,
        description: newTag,
    };
    state.motifs.push(tag);
    motifs.value.data.data = [tag, ...motifs.value.data.data];
};

const CopyLink = (urlPatient = '') => {
    copy(urlPatient);
    $toast.success('Le lien de la vidéo conférence a été copier avec succès', {
        position: 'bottom-right',
    });
};
const addMotifSecondaire = (newTag: any) => {
    const tag = {
        id: null,
        description: newTag,
    };
    state.motifs_secondaires.push(tag);
    motifs.value.data.data = [tag, ...motifs.value.data.data];
};
const addAllergie = (newTag: any) => {
    const tag = {
        id: null,
        description: newTag,
    };
    state.allergies.push(tag);
    dataAllergieMap.value = [tag, ...dataAllergieMap.value];
};

const addExamenComplementaire = (newTag: any) => {
    const tag = {
        id: null,
        description: newTag,
    };
    state.examens.push(tag);
    dataExamenMap.value = [tag, ...dataExamenMap.value];
};

const dateToFormat = (date: string) => moment(date).format('DD-MM-YYYY HH:mm');

onMounted(() => {
    //console.log("fullPath" ,route.fullPath)
    store.dispatch('allergie/fetchAllergiesAction');
    store.dispatch('etablissement/fetchEtablissements');
    store.dispatch('anamnese/fetchAnamnese');
    store.dispatch('examen/fetchExamen');
    store.dispatch('examen_clinics/getExamen_clinic');
    store.dispatch('motif/fetchMotifs');
    store.dispatch('types/fetchTypesTeleconsultation', 'teleconsultations');
    store.dispatch('types/fetchTypesAntecedent', 'antecedents');

    setTimeout(() => {
        store.dispatch('teleconsultations/fetchAlerte', {
            medecin_id: user_id.value,
            patient_id: patient_id.value,
        });
    }, 5000);
    store.dispatch('teleconsultations/fetchAllergies', patient_id.value);
    store.dispatch('teleconsultations/fetchAntecedents', patient_id.value);
    // store.dispatch("teleconsultations/getRendezVous")
});

const motifValidate = ref(false);
computed(() => {
    motifValidate.value = true;
    if (state.rendezVous && state.motif === '' && state.date === '') {
        return (motifValidate.value = false);
    } else if (state.rendezVous && state.motif != '' && state.date != '') {
        return (motifValidate.value = false);
    }
});

const createdTeleconsultation = (states: {}) => {
    console.log('states ', state);
    v$.value.$validate();
    if (
        !v$.value.$error &&
        state.rendezVous &&
        state.motif != '' &&
        state.date != ''
    ) {
        store.dispatch('teleconsultations/createdTeleconsultation', states);
    } else if (
        !v$.value.$error &&
        !state.rendezVous &&
        state.motif === '' &&
        state.date === ''
    ) {
        store.dispatch('teleconsultations/createdTeleconsultation', states);
    }
};

const describe = ref(false);

// computed(() => {
//     if (state.value == ""){
//         describe.value = true;
//     }
//     describe.value = false;
// })

const AnamneseDescribe = (value: string) => {
    state.anamnese_id = value;
    describe.value = false;
    if (state.anamnese_id !== '') describe.value = true;
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
