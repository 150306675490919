<template lang="">
    <div>
        <button
            type="button"
            @click="viewAnamnese"
            class="btn border d-flex align-items-center gap-1 m-2"
        >
            <i class="fa-solid fa-plus"></i> Ajouter
        </button>
        <Modal
            v-model="newAnamnese"
            title="Nouvelles Anamnese"
            bgcolor="#172b4d"
            colorTitle="white"
            width="lg"
        >
            <div class="my-3 d-flex justify-content-center" v-if="loader">
                <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin" />
            </div>

            <div v-if="!loader">
                <Anamnese-Form :state="state" :v$="v$" />
                <button class="btn btn-primary" @click="createAnamnese">
                    Enregistrer
                </button>
            </div>
        </Modal>
    </div>
</template>
<script setup>
import AnamneseForm from './AnamneseForm.vue';
import Modal from './../Modal.vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { ref, reactive, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
    teleconsultation_id: {
        type: Number,
        required: true,
    },
});

const store = useStore();

const newAnamnese = ref(false);
const anamnese_id = computed(() => {
    return store.state.anamnese.anamnese?.data.map((anamnese) => anamnese.id);
});

const rules = computed(() => {
    return {
        anamnese_id: { required },
        description: { required },
    };
});

const state = reactive({
    anamnese_id: [],
    description: '',
    teleconsultation_id: props.teleconsultation_id,
});

const v$ = useVuelidate(rules, state);

const viewAnamnese = () => {
    newAnamnese.value = true;
};

const createAnamnese = () => {
    v$.value.$validate();
    if (!v$.value.$error) {
        store.dispatch('anamnese/createdAnamnese', state).then((res) => {
            state.type = [];
            state.description = '';
            newAnamnese.value = false;
        });
    }
};
onMounted(() => {
    store.dispatch('anamnese/fetchAnamnese');
});
const loader = computed(() => store.state.anamnese.loadingStatus);
</script>
