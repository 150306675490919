import { Patient } from '../_types/patient';

import Axios from '../api';

class PatientService {
    path: string;
    pathA: string;

    constructor() {
        this.path = '/patients';
        this.pathA = '/antecedents';
    }

    /*  async getTeleconsultations(page = 1, page_size = 10):  Promise<Pagination> {
        return await Axios.get(`${this.path}?page_size=${page_size}&page=${page}`);
    }; */

    async getPatient(id: number | string, associations = ''): Promise<Patient> {
        return await Axios.get(
            `${this.path}/${id}?associations=${associations}`
        );
    }

    async searchPatient(search: string): Promise<Patient> {
        return await Axios.get(`${this.path}?search=${search}`);
    }

    /* async createdTeleconsultation(teleconsultation: Teleconsultation): Promise<Teleconsultation> {
        return await Axios.post(this.path, teleconsultation);
    }

    async updatedTeleconsultation(id: number, teleconsultation: Teleconsultation): Promise<Teleconsultation> {
        return await Axios.patch(`${this.path}/${id}`, teleconsultation);
    }

    async deletedTeleconsultation(id: number): Promise<Teleconsultation> {
        return await Axios.delete(`${this.path}/${id}`);
    } */

    //patient antécedant
    async getAntecedent(): Promise<any> {
        return await Axios.get(`${this.pathA}`);
    }

    async createdAntecedent(antecedant: any): Promise<any> {
        return await Axios.post(this.pathA, antecedant);
    }

    async deleteAntecedent(antecedant_id: number): Promise<any> {
        return await Axios.delete(`${this.pathA}/${antecedant_id}`);
    }
}

export default new PatientService();
