import Axios from '../api';
import { Bulletin } from '../_storeV2/bulletin/index';

class BulletinAnalyse {
    path: string;
    constructor() {
        this.path = '/examen_analyses';
    }
    async getBulletins(page = 1, page_size = 5, search = ''): Promise<any> {
        return await Axios.get(
            `${this.path}?page_size=${page_size}&page=${page}&search=${search}`
        );
    }
    async getBulletin(uuid: number): Promise<any> {
        return await Axios.get(`${this.path}/${uuid}`);
    }

    async createBulletin(data: any): Promise<any> {
        return await Axios.post(this.path, data);
    }
    async deleteBulletin(id: any): Promise<any> {
        return await Axios.delete(`${this.path}/${id}`);
    }

    async updateBulletin(uuid: any, data: any): Promise<any> {
        return await Axios.patch(`${this.path}/${uuid}`, data);
    }

    async printBulletin(bulletin_id: number): Promise<any> {
        return await Axios.get(`${this.path}/print/${bulletin_id}`);
    }
}

export default new BulletinAnalyse();
