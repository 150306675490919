<template lang="">
    <div class="">
        <fieldset class="rounded">
            <!-- <legend class="float-none w-auto p-2 text-h3 fwn">Etape: Documentation du Bon d'examens d'analyses biomédicales</legend> -->
            <div class="mx-2">
                <div class="form-group mb-4">
                    <div class="w-100 d-block">
                        <label class="default"
                            >Evènement médical associé<!-- <span class="red">*</span> --></label
                        >
                        <!-- :custom-label="nameLigne" -->
                        <VueMultiselect
                            v-model="state.evenement_medical.id"
                            :options="timelineData"
                            :preserve-search="true"
                            placeholder="Rechercher un Patient"
                            label="description"
                            track-by="id"
                            :preselect-first="false"
                            @select="
                                $emit(
                                    'update:state.evenement_medical',
                                    $event.target
                                )
                            "
                        >
                            <template v-slot:option="{ props }">
                                <strong>{{ props.option.description }}</strong>
                            </template>
                        </VueMultiselect>
                        <!-- <VueMultiselect v-model="state.evenement_medical" :options="timelineData" :preserve-search="true" placeholder="Rechercher un Patient" label="description" track-by="id" :preselect-first="false" >
                        </VueMultiselect> -->
                        <!-- <small v-if="v$.evenement_medical.$error && v$.evenement_medical.$invalid" class="red list-none ">
                            {{ "sélectionner un évenement médical associé" }}
                        </small> -->
                    </div>
                </div>
                <div class="mb-4">
                    <label for=""
                        >Niveau d'urgence de la prescription<span class="red"
                            >*</span
                        ></label
                    >
                    <div class="border rounded p-2">
                        <div class="d-flex flex-wrap align-items-center gap-2">
                            <b-form-radio
                                v-model="state.niveau_urgence.id"
                                v-for="(niveau_urgence, index) in niveauUrgence"
                                :key="index"
                                name="niveau-urgence"
                                :value="niveau_urgence"
                            >
                                Urgence {{ niveau_urgence.valeur }}
                            </b-form-radio>
                            <!-- <b-form-radio 
                                v-model="props.state.niveau_urgence">
                                Urgence {{niveauUrgence}}
                            </b-form-radio> -->
                        </div>
                        <div
                            v-if="state.niveau_urgence"
                            class="mt-2 p-2 rounded"
                            :class="[
                                state.niveau_urgence.id.valeur == 1
                                    ? 'urgence-1'
                                    : state.niveau_urgence.id.valeur == 3
                                    ? 'urgence-3'
                                    : state.niveau_urgence.id.valeur == 4
                                    ? 'urgence-4'
                                    : state.niveau_urgence.id.valeur == 5
                                    ? 'urgence-5'
                                    : 'urgence-1',
                            ]"
                        >
                            <small>
                                {{ state.niveau_urgence?.id.description }}
                            </small>
                        </div>
                    </div>
                    <!-- <small v-if="v$.niveau_urgence.$error && v$.niveau_urgence.$validate" class="red list-none ">
                        {{ "sélectionner le niveau d'urgence" }}
                    </small> -->
                </div>
                <div class="mb-4">
                    <label for=""
                        >Option de financement<span class="red">*</span></label
                    >
                    <div class="border rounded p-2">
                        <b-form-checkbox-group
                            v-model="state.option_financement.id"
                            :options="optionfinancement"
                            class="mb-3 d-flex flex-wrap"
                            value-field="id"
                            text-field="libelle"
                            disabled-field="notEnabled"
                        ></b-form-checkbox-group>
                    </div>
                    <!-- <small v-if="v$.option_financement.$error && v$.option_financement.$invalid" class="red list-none ">
                        {{ "sélectionner au moins une option financiere" }}
                    </small> -->
                </div>
                <div class="mb-2">
                    <label for=""
                        >Raison de la prescription<span class="red"
                            >*</span
                        ></label
                    >
                    <div class="border rounded p-2">
                        <div class="d-flex flex-wrap align-items-center gap-2">
                            <b-form-radio
                                v-model="state.raison_prescription.id"
                                v-for="(
                                    raison_prescription, index
                                ) in raisonPrescription"
                                :key="index"
                                name="raison-prescription"
                                :value="raison_prescription"
                                disabled-field="notEnabled"
                            >
                                {{ raison_prescription?.libelle }}
                            </b-form-radio>
                            <!-- <b-form-radio 
                                v-model="state.raison_prescription"
                                v-for="(raison_prescription, index) in raisonPrescription" :key="index" 
                                name="raison-prescription" :value="raison_prescription">
                                {{raison_prescription?.libelle}}
                            </b-form-radio> -->
                        </div>
                        <!-- <div class="mt-3">Selected: <strong>{{ state.raison_prescription.id }}</strong></div> -->
                    </div>
                    <!-- <small v-if="v$.raison_prescription.$error" class="red list-none ">
                        {{ "sélectionner la raison de prescription" }}
                    </small> -->
                </div>
            </div>
        </fieldset>
    </div>
</template>
<script setup lang="ts">
import { ref, reactive, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import VueMultiselect from 'vue-multiselect';
import moment from 'moment';
import NiveauUrgenceService from '@/_services/NiveauUrgenceService';
import OptionFinancementService from '@/_services/OptionFinancementService';
import TimelineService from '@/_services/TimelineService';
import { toRefs, watch } from 'vue';
import PrescriptionService from '@/_services/PrescriptionService';

const props = defineProps({
    state: {
        type: Object,
        required: true,
        default: false,
    },
    v$: {
        type: Object,
        required: true,
        default: false,
    },
});

const store = useStore();
// const emit = defineEmits(['update:state.evenement_medical'])
// function updateValue(option:any) {
//   emit('update:state.evenement_medical', option.description)
// }
const niveauUrgence = ref([]);
const NiveauDurgence = async () => {
    await NiveauUrgenceService.getNiveau_urgences()
        .then((response) => {
            if (niveauUrgence.value) {
                niveauUrgence.value = response.data.data;
            } else {
                return niveauUrgence.value;
            }
        })
        .catch((error) => {
            console.log(error);
        });
};

// nameLigne ({ date, motif }) {
//     return `${motif} — ${date}`
// },

const optionfinancement = ref([]);
const Optionfinancement = async () => {
    await OptionFinancementService.getOptionFinancement()
        .then((response) => {
            optionfinancement.value = response.data.data;
        })
        .catch((error) => {
            console.log(error);
        });
};

// const onSelect = (option:any) =>{
//     props.state.evenement_medical = option.description
//     }

const timelineData = ref([]);
const timelineDate = ref([]);
const patient_id = store.state.patient.select.user?.id;

const fetchTimelinePatient = async () => {
    await TimelineService.getTimeline(patient_id, 'patient')
        .then((response) => {
            timelineData.value = response.data.map((data: any) => ({
                id: data.id,
                description: data.description,
            }));
        })
        .catch((error) => {
            console.log(error);
        });
};

// const nameLigne = () => {
//     let date = moment(timelineDate.value).format("YYYY-MM-DD");
//     return timelineData.value + " — " + date;
// }

const raisonPrescription = ref([]);

const fetchRaisonPrescription = async () => {
    await PrescriptionService.getRaisonPrescriptions()
        .then((response) => {
            raisonPrescription.value = response.data.data;
        })
        .catch((error) => {
            console.log(error);
        });
};

onMounted(() => {
    NiveauDurgence();
    Optionfinancement();
    fetchTimelinePatient();
    fetchRaisonPrescription();
    // props.state.state.evenement_medical = timelineData.value.find(option => option.id === props.state.state.evenement_medical);
});

// https://www.back.medsurlink.com/api/timelines/nouveau-1675184537-34046734/patient
</script>
