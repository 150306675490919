const baseLocal = {
    apiMedsurlink: 'http://127.0.0.1:8000/api/v2',
    socket: 'http://127.0.0.1:3000',
    //socket: "https://staging-notifications.medsurlink.com",
    apiMedicaments: 'https://medicaments.medsurlink.com',
};

const baseStaging = {
    apiMedsurlink: 'https://www.staging.medsurlink.com/api/v2',
    socket: 'https://staging-notifications.medsurlink.com',
    apiMedicaments: 'https://medicaments.medsurlink.com',
};

const baseProd = {
    apiMedsurlink: 'https://www.back.medsurlink.com/api/v2',
    socket: 'https://notifications.medsurlink.com',
    apiMedicaments: 'https://medicaments.medsurlink.com',
};

export const ENV = {
    ...baseStaging,
};
