import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Layout from '../views/Admin/Layout.vue';
import EprescriptionLayout from '../views/Admin/ePrescription/EprescriptionLayout.vue';
import EprescriptionList from '../views/Admin/ePrescription/EprescriptionList.vue';
import EprescriptionAffichage from '../views/Admin/ePrescription/EprescriptionAffichage.vue';
import AddPrescription from '../views/Admin/ePrescription/AddPrescription.vue';
import HomePrescription from '../views/Admin/ePrescription/HomePrescription.vue';
import TeleconsultationDashboard from '@/views/Admin/Teleconsultation/TeleconsultationDashboard.vue';
import BonPriseEnChargeList from '@/views/Admin/bonPriseEnCharge/BonPriseEnChargeList.vue';
import BonDePriseEnChargeForm from '@/views/Admin/bonPriseEnCharge/BonDePriseEnChargeForm.vue';
import TeleconsultationLayout from '@/views/Admin/Teleconsultation/TeleconsultationLayout.vue';
import TeleconsultationForm from '@/views/Admin/Teleconsultation/TeleconsultationForm.vue';
import NotificationList from '@/views/Admin/alert/NotificationList.vue';
import BonPriseEnChargeForm from '@/views/Admin/bonPriseEnCharge/BonDeDePriseEnChargeFormForm.vue';
import AlerteList from '@/views/Admin/alertes/AlerteList.vue';
import TeleconsultationDetail from '@/components/organisms/TeleconsultationDetail.vue';
import TeleconsultationAffichage from '@/views/Admin/Teleconsultation/TeleconsultationAffichage.vue';
import BulletinAffichage from '@/views/Admin/bulletin/BuletinAffichage.vue';
import BonPriseEnChargeAffichage from '@/views/Admin/bonPriseEnCharge/BonPriseEnChargeAffichage.vue';
import BulletinExamen from '../views/Admin/bulletin/BulletinExamen.vue';
import BulletinExamenLayout from '@/views/Admin/bulletin/BulletinExamenLayout.vue';
import BonPriseEnChargeLayout from '@/views/Admin/bonPriseEnCharge/BonPriseEnChargeLayout.vue';
import BonPriseEnCharge from '@/views/Admin/bonPriseEnCharge/BonPriseEnCharge.vue';
import EmailSms from '@/views/Admin/emailSms/EmailSms.vue';
import EmailSmsList from '@/views/Admin/emailSms/EmailSmsList.vue';
import AddEmailSms from '@/views/Admin/emailSms/AddEmailSms.vue';
import EmailSmsAffichage from '@/views/Admin/emailSms/EmailSmsAffichage.vue';
import HomeEmailSms from '@/views/Admin/emailSms/HomeEmailSms.vue';

import ExamenImagerieLayout from '@/views/Admin/examen_imageries/ExamenImagerieLayout.vue';
import ExamenImagerie from '@/views/Admin/examen_imageries/ExamenImagerie.vue';

import Home from '../views/Home.vue';

//fichier de vérification et récupération du token d'existance du token
import { authGuard } from '@/_helpers/auth-guard';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue'),
    },
    {
        path: '/admin',
        name: 'Dashboard',
        component: () => import('../views/Dashboard.vue'),
        children: [
            {
                path: '/admin',
                name: 'Layout',
                component: Layout,
                children: [
                    {
                        path: '/',
                        name: 'teleconsultation-layout',
                        component: TeleconsultationLayout,
                        meta: {
                            breadcrumb: { name: 'téléconsultation' },
                        },
                        children: [
                            {
                                path: '/',
                                name: 'teleconsultations',
                                component: TeleconsultationDashboard,
                                meta: {
                                    breadcrumb: {
                                        name: 'listing',
                                        parent: 'téléconsultations',
                                    },
                                },
                            },
                            {
                                path: 'users',
                                component: Home,
                                children: [
                                    {
                                        path: '/teleconsultations/create',
                                        name: 'AddTeleconsultation',
                                        component: TeleconsultationForm,
                                        meta: {
                                            breadcrumb: {
                                                name: 'création',
                                                parent: 'téléconsultations',
                                            },
                                        },
                                    },
                                    {
                                        path: '/teleconsultations/:uuid',
                                        name: 'teleconsultation-detail',
                                        component: TeleconsultationDetail,
                                        props: true,
                                        meta: {
                                            breadcrumb: {
                                                name: 'détail',
                                                parent: 'téléconsultations',
                                            },
                                        },
                                    },
                                    {
                                        path: '/teleconsultations/affichage/:uuid',
                                        name: 'teleconsultation-affichage',
                                        component: TeleconsultationAffichage,
                                        props: true,
                                        meta: {
                                            breadcrumb: {
                                                name: 'affichage',
                                                parent: 'téléconsultations',
                                            },
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: '/e-prescription',
                        name: 'eprecription-layout',
                        component: EprescriptionLayout,
                        meta: {
                            breadcrumb: {
                                name: 'prescription',
                                parent: 'prescriptions',
                            },
                        },
                        children: [
                            {
                                path: '/e-prescription',
                                name: 'e-prescription',
                                component: EprescriptionList,
                            },
                            {
                                path: '/prescription',
                                component: HomePrescription,
                                meta: {
                                    breadcrumb: {
                                        name: 'prescription',
                                        parent: 'prescriptions',
                                    },
                                },
                                children: [
                                    {
                                        path: '/prescription/create',
                                        name: 'AddEPrescription',
                                        component: AddPrescription,
                                        meta: {
                                            breadcrumb: {
                                                name: 'création',
                                                parent: 'prescriptions',
                                            },
                                        },
                                    },

                                    {
                                        path: '/modification-eprescription/:uuid',
                                        name: 'modification-eprescription',
                                        component: AddPrescription,
                                        props: true,
                                        meta: {
                                            breadcrumb: {
                                                name: 'édition',
                                                parent: 'prescriptions',
                                            },
                                        },
                                    },
                                    {
                                        path: '/eprescription-affichage/:uuid',
                                        name: 'eprescription-affichage',
                                        component: EprescriptionAffichage,
                                        props: true,
                                        meta: {
                                            breadcrumb: {
                                                name: 'Affichage',
                                                parent: 'prescriptions',
                                            },
                                        },
                                    },
                                ],
                            },
                        ],
                    },

                    //notification route
                    {
                        path: '/notifications',
                        name: 'notification',
                        component: NotificationList,
                        meta: {
                            breadcrumb: {
                                name: 'listing',
                                parent: 'notifications',
                            },
                        },
                    },

                    // {
                    //   path: '/prise-en-charge',
                    //   name: 'priseEnCharge',
                    //   component: BonPriseEnChargeList,
                    //   meta: {
                    //     breadcrumb: {name: 'Bon de prise en charge', parent: 'prise-en-charge'}
                    //   }
                    // },
                    // {
                    //   path: '/prise-en-charge/create',
                    //   name: 'AddPriseEnCharge',
                    //   component: BonDePriseEnChargeForm,
                    //   meta: {
                    //     breadcrumb: {name: 'création', parent: 'prise-en-charge'},
                    //   }
                    // },
                    {
                        path: '/alertes',
                        name: 'alertes',
                        component: AlerteList,
                        meta: {
                            breadcrumb: { name: 'listing', parent: 'alertes' },
                        },
                    },

                    // email-sms routes
                    {
                        path: '/email-sms',
                        name: 'email-sms',
                        component: EmailSms,
                        meta: {
                            breadcrumb: {
                                name: 'email-sms',
                                parent: 'email-sms',
                            },
                        },
                        children: [
                            {
                                path: '/email-sms',
                                name: 'email-sms-list',
                                component: EmailSmsList,
                            },
                            {
                                path: '/email-sms',
                                component: HomeEmailSms,
                                meta: {
                                    breadcrumb: {
                                        name: 'email-sms',
                                        parent: 'email-sms',
                                    },
                                },
                                children: [
                                    {
                                        path: '/email-sms/create',
                                        name: 'AddEmailSms',
                                        component: AddEmailSms,
                                        meta: {
                                            breadcrumb: {
                                                name: 'création',
                                                parent: 'email-sms',
                                            },
                                        },
                                    },
                                    {
                                        path: '/email-sms-affichage/:uuid',
                                        name: 'email-sms-affichage',
                                        component: EmailSmsAffichage,
                                        props: true,
                                        meta: {
                                            breadcrumb: {
                                                name: 'Affichage',
                                                parent: 'email-sms',
                                            },
                                        },
                                    },
                                ],
                            },
                        ],
                    },

                    //bulletin demande d'analyse biomedical route
                    {
                        path: '/bulletin-examen',
                        component: BulletinExamenLayout,
                        meta: {
                            breadcrumb: {
                                name: 'listing',
                                parent: 'bulletin-examen',
                            },
                        },
                        children: [
                            {
                                path: '/bulletin-examen',
                                name: 'bulletin-examen',
                                component: () =>
                                    import(
                                        '../views/Admin/bulletin/AnalyseMedicalList.vue'
                                    ),
                            },
                            {
                                path: 'bulletin-examen',
                                component: BulletinExamen,
                                children: [
                                    {
                                        path: '/bulletin-examen/create',
                                        name: 'create-bulletin-examen',
                                        component: () =>
                                            import(
                                                '../views/Admin/bulletin/AnalyseMedicalForm.vue'
                                            ),
                                        meta: {
                                            breadcrumb: {
                                                name: 'création',
                                                parent: 'bulletin-examen',
                                            },
                                        },
                                    },
                                    {
                                        path: '/bulletin-examen/edit/:uuid',
                                        name: 'modification-bulletin-examen',
                                        component: () =>
                                            import(
                                                '../views/Admin/bulletin/AnalyseMedicalForm.vue'
                                            ),
                                        props: true,
                                        meta: {
                                            breadcrumb: {
                                                name: 'édition',
                                                parent: 'bulletin-examen',
                                            },
                                        },
                                    },
                                    {
                                        path: '/bulletin-affichage/:uuid',
                                        name: 'bulletin-affichage',
                                        component: BulletinAffichage,
                                        props: true,
                                        meta: {
                                            breadcrumb: {
                                                name: 'Affichage',
                                                parent: 'bulletin',
                                            },
                                        },
                                    },
                                ],
                            },
                        ],
                    },

                    //Imagerie médicale routes
                    {
                        path: '/imageries-medicales',
                        name: 'layout-imagerie-medical',
                        component: ExamenImagerieLayout,
                        meta: {
                            breadcrumb: {
                                name: 'listing',
                                parent: 'imageries-medicales',
                            },
                        },
                        children: [
                            {
                                path: '/imageries-medicales',
                                name: 'layout-imagerie-list',
                                component: () =>
                                    import(
                                        '../views/Admin/examen_imageries/ExamenImagerieList.vue'
                                    ),
                            },
                            {
                                path: 'imageries-medicales',
                                component: ExamenImagerie,
                                children: [
                                    {
                                        path: '/imageries-medicales/create',
                                        name: 'create-imageries-medicales',
                                        component: () =>
                                            import(
                                                '../views/Admin/examen_imageries/ExamenImagerieCreate.vue'
                                            ),
                                        meta: {
                                            breadcrumb: {
                                                name: 'création',
                                                parent: 'imageries-medicales',
                                            },
                                        },
                                    },
                                    {
                                        path: '/imageries-medicales/edit/:uuid',
                                        name: 'modification-imageries-medicales',
                                        component: () =>
                                            import(
                                                '../views/Admin/examen_imageries/ExamenImagerieCreate.vue'
                                            ),
                                        props: true,
                                        meta: {
                                            breadcrumb: {
                                                name: 'édition',
                                                parent: 'imageries-medicales',
                                            },
                                        },
                                    },
                                    {
                                        path: '/imageries-medicales/:uuid',
                                        name: 'imagerie-detail',
                                        component: () =>
                                            import(
                                                '../views/Admin/examen_imageries/ExamenImagerieAffichage.vue'
                                            ),
                                        props: true,
                                        meta: {
                                            breadcrumb: {
                                                name: 'modification',
                                                parent: 'imageries-medicales',
                                            },
                                        },
                                    },
                                ],
                            },
                        ],
                    },

                    //bon de prise en charge route
                    {
                        path: '/bons-de-prises-en-charge',
                        name: 'bons-prises-en-charge',
                        component: BonPriseEnChargeLayout,
                        meta: {
                            breadcrumb: {
                                name: 'listing',
                                parent: 'bons-de-prises-en-charge',
                            },
                        },
                        children: [
                            {
                                path: '/bons-de-prises-en-charge',
                                name: 'listing-bon-de-prise-en-charge',
                                component: () =>
                                    import(
                                        '../views/Admin/bonPriseEnCharge/BonPriseEnChargeList.vue'
                                    ),
                            },
                            {
                                path: '/bon-de-prise-en-charge/create',
                                component: BonPriseEnCharge,
                                children: [
                                    {
                                        path: '/bon-de-prise-en-charge/create',
                                        name: 'create-bon-de-prise-en-charge',
                                        component: () =>
                                            import(
                                                '../views/Admin/bonPriseEnCharge/BonDePriseEnChargeForm.vue'
                                            ),
                                        meta: {
                                            breadcrumb: {
                                                name: 'création',
                                                parent: 'bons-de-prises-en-charge',
                                            },
                                        },
                                    },
                                    {
                                        path: '/bon-de-prise-en-charge/edit/:uuid',
                                        name: 'edit-bon-de-prise-en-charge',
                                        component: () =>
                                            import(
                                                '../views/Admin/bonPriseEnCharge/BonDePriseEnChargeForm.vue'
                                            ),
                                        meta: {
                                            breadcrumb: {
                                                name: 'édition',
                                                parent: 'bons-de-prises-en-charge',
                                            },
                                        },
                                    },
                                    {
                                        path: '/bons-de-prises-en-charge-affichage/:uuid',
                                        name: 'bons-de-prises-en-charge-affichage',
                                        component: BonPriseEnChargeAffichage,
                                        meta: {
                                            breadcrumb: {
                                                name: 'Affichage',
                                                parent: 'bons-de-prises-en-charge',
                                            },
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

            //signature utilisateur route
            {
                path: '/signature',
                name: 'signature',
                component: () => import('../views/signature/Signature.vue'),
                meta: {
                    breadcrumb: { name: 'signature', parent: 'utilisateur' },
                },
            },
        ],
    },
    {
        path: '/:pathMatch(.*)',
        redirect: '/',
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: 'active',
});

router.beforeEach((to, from, next) => {
    if (to.matched[0].name == 'Dashboard') {
        authGuard(to);
    }
    next();
});

export default router;
