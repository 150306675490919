<template>
    <div class="mb-3">
        <label class="typo__label default">Rechercher/Ajouter un Patient</label>
        <div class="w-100 d-block d-md-flex align-items-center" v-if="datas">
            <VueMultiselect
                v-model="find"
                :options="searchData"
                :preserve-search="true"
                placeholder="Rechercher un Patient"
                label="nom"
                :preselect-first="false"
                @search-change="DataPatient"
                :loading="isLoading"
                @select="confirmPatient(find)"
            >
            </VueMultiselect>
        </div>
        <div class="" v-if="find.user_id">
            <p class="pb-2 mt-4 default">
                Informations sur le patient selectionné:
            </p>
            <div class="container default">
                <div class="row">
                    <div class="col">
                        <p>
                            <i class="fa-solid fa-user text-secondary mx-1"></i
                            >Nom: <strong>{{ find.nom }}</strong>
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <p>
                            <i class="fa-solid fa-user text-secondary mx-1"></i
                            >Prenom: <strong>{{ find.prenom }}</strong>
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <p>
                            <i
                                class="fa-solid fa-address-book text-secondary mx-1"
                            ></i
                            >Adresse: <strong>{{ find.user_id }}</strong>
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <p>
                            <i
                                class="fa-solid fa-calendar-days text-secondary mx-1"
                            ></i
                            >Date de Naissance:
                            <strong>{{ find.date_de_naissance }}</strong>
                        </p>
                    </div>
                </div>
                <div class="row border-bottom-dashed">
                    <div class="col">
                        <p>
                            <i
                                class="fa-solid fa-person text-secondary mx-1"
                            ></i
                            >Sexe: <strong>{{ find.sexe }}</strong>
                        </p>
                    </div>
                    <div class="col">
                        <p>
                            <i
                                class="fa-solid fa-calendar-days text-secondary mx-1"
                            ></i>
                            Age: <strong>{{ find.age }}</strong>
                        </p>
                    </div>
                </div>
                <div
                    @click="patientSelect = true"
                    class="form-check p-0 cursor-pointer my-2"
                    v-if="!patientSelect"
                >
                    <i
                        :class="'primary'"
                        class="fa-regular fa-square-check"
                    ></i>
                    <label
                        class="form-check-label mx-2 cursor-pointer"
                        for="patient"
                    >
                        Confirmé le patient sélectionné
                    </label>
                </div>
                <div
                    @click="
                        SelectPatient(find.user_id, datas),
                            (patientSelect = false)
                    "
                    class="form-check p-0 cursor-pointer my-2"
                    v-if="patientSelect"
                >
                    <i class="fa-regular fa-square"></i>
                    <label
                        class="form-check-label mx-2 cursor-pointer"
                        for="patient"
                    >
                        Confirmé le patient sélectionné
                    </label>
                </div>
                <div
                    class="d-flex justify-content-center mt-4"
                    v-if="!patientSelect"
                >
                    <router-link
                        @click="closeModal"
                        to="/TeleconsultationForm"
                        class="btn btn-primary py-2 shadow"
                        >Continuer vers le formulaire</router-link
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import VueMultiselect from 'vue-multiselect';
import PatientService from '@/_services/PatientService';
import { useStore } from 'vuex';
import { ref, onMounted, computed } from 'vue';

const store = useStore();

const isLoading = ref(false);

const find = ref([]);
const searchData: [] | any = ref([]);
const datas = 'user';

// const DataPatient = async (find: "") => {
//     await PatientService.searchPatient(find).then((res: any) => {
//       const patientData: any = res.data
//       searchData.value = patientData.data.map((item: any) => {
//         return {
//           date_de_naissance: item.date_de_naissance,
//           sexe: item.sexe,
//           age: item.age,
//           nom: item.user?.nom,
//           prenom: item.user?.prenom,
//           user_id: item.user_id,
//         };
//       })
//       console.log("res==>", searchData.value);
//       return searchData.value
//     }).catch((error: any) => {
//       console.log("error ", error)
//     })
//   }
let display = store.state.composable.display;
const dialogVisible = ref(false);
function closeModal() {
    display = !display;
}

const DataPatient = (find: '') => {
    isLoading.value = true;
    store.dispatch('patient/searchPatient', find);
    searchData.value = store.state.patient.patient;
    isLoading.value = false;
};

function SelectPatient(id: 0, associations: '') {
    store.dispatch('patient/fetchPatient', {
        id: id,
        associations: associations,
    });
}

const patientSelect = ref(false);
const confirmPatient = (patient: '') => {
    patientSelect.value = true;
};

const props = defineProps({
    dialogVisible: Boolean,
});
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
