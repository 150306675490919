/**importation des modules nécessaireces */

//module decorateur
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import AccountService from '@/_services/accountService';
import router from '@/router';
import { useToast } from 'vue-toast-notification';
import VuexPersistence from 'vuex-persist';

const storedUser = localStorage.getItem('ms-token');

@Module({
    namespaced: true,
})
export default class UsersModule extends VuexModule {
    public status = storedUser ? { loggedIn: true } : { loggedIn: false };
    public user: [] | null = [];
    public signature = true;
    public ms_token = '';
    public patient_slug = '';

    //state loader for login
    public loginLoader = false;

    $toast = useToast();

    get isLoggedIn(): boolean {
        return this.status.loggedIn;
    }

    get gUser(): any {
        return this.user;
    }

    get token(): string {
        return this.ms_token;
    }
    get patient(): string {
        return this.patient_slug;
    }

    //getters loading
    get gloadingLogin() {
        return this.loginLoader;
    }

    @Mutation
    public setPatientSlug(patient_slug: string): void {
        this.patient_slug = patient_slug;
    }

    @Mutation
    public setToken(ms_token: string): void {
        this.ms_token = ms_token;
    }

    @Mutation
    public setSignature(cam = { signature: true, sign: '' }): void {
        this.signature = cam.signature;
        // this.user.signature = this.signature_l;
    }

    @Mutation
    public loginSuccess(user: any): void {
        this.user = user;
        this.status.loggedIn = true;
        //this.signature = user.user.signature == null ? true : false;
    }
    @Mutation
    public setUser(user: any) {
        this.user = user;
    }

    @Mutation
    public loginFailure(): void {
        this.status.loggedIn = false;
        this.user = null;
    }

    @Mutation
    public logout(): void {
        this.status.loggedIn = false;
        this.user = null;
    }

    @Mutation
    mloadingLogin(newStatus: boolean) {
        this.loginLoader = newStatus;
    }

    @Action
    login(user: any): Promise<any> {
        this.context.commit('mloadingLogin', true);
        return AccountService.login(user).then(
            (res) => {
                if (res.data?.user.signature != null) {
                    this.context.commit('setSignature', {
                        signature: false,
                        sign: '',
                    });
                }
                this.context.commit('loginSuccess', res.data);
                this.context.commit('setUser', res.data.user);

                this.context.dispatch(
                    'notifications/setNotifications',
                    res.data.user.unread_notifications,
                    { root: true }
                );
                console.log('user', res.data.user.roles[0].name);
                if (
                    res.data.access_token &&
                    res.data.user.roles[0].name === 'Medecin controle'
                ) {
                    this.context.commit('mloadingLogin', false);
                    //set access-token
                    this.context.commit('setToken', res.data.access_token);
                    localStorage.setItem('ms-token', res.data.access_token);
                    this.context.commit('setToken', res.data.access_token);
                    // this.$toast.success('connexion réussie', { position: "top-right" })
                    router.push('/');
                } else {
                    this.$toast.error(
                        "Vous n'êtes pas autorisé à accéder à ce module.",
                        { position: 'top-right' }
                    );
                    this.context.commit('mloadingLogin', false);
                    router.push('/login');
                }
                return true;
            },
            (error) => {
                console.log('state err', error);
                this.context.commit('loginFailure');

                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                return Promise.reject(message);
            }
        );
    }

    @Action
    signOut(): void {
        AccountService.logout();
        this.context.commit('logout');
        router.push('/login');
    }

    @Action
    UpdateToken(token: string): void {
        AccountService.saveToken(token);
        this.context.commit('setToken', token);

        AccountService.setUser().then(
            (res) => {
                console.log('exécution', res.data);
                this.context.commit('loginSuccess', res.data);
            },
            (err) => console.log(err)
        );
    }

    @Action
    UpdatePatientSlug(patient_slug: string): void {
        AccountService.saveCurrentPatientSlug(patient_slug);
        this.context.commit('setPatientSlug', patient_slug);
    }

    @Action
    updateSignature(signature_1: string): void {
        this.context.commit('setSignature', {
            signature: false,
            sign: signature_1,
        });
    }

    @Action
    async saveSignature(user = { signature: '', id: 0 }) {
        this.context.commit('mloadingLogin', true);
        await AccountService.saveSignature(user.signature, user.id).then(
            (res) => {
                this.context.commit('mloadingLogin', false);
                const result = {
                    ...this.context.rootState.users.user,
                    signature: res.data.signature,
                };
                this.context.commit('setUser', result);
                this.$toast.success('Votre signature a bien été enregistrée', {
                    position: 'top-right',
                });
            },
            (err) => {
                console.log(err);
                this.context.commit('mloadingLogin', false);
            }
        );
    }
}

[new VuexPersistence().plugin];
