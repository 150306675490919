<template lang="">
    <div>
        <div v-for="(item, index) in form" :key="item">
            <h2>{{ title }} {{ index + 1 }}</h2>
            <div class="mb-3 row align-items-center">
                <div class="col-7">
                    <input
                        type="text"
                        v-model="item.description"
                        class="form-control"
                        placeholder="description allergie"
                    />
                    <li
                        v-if="v$.collection.$each.$invalid"
                        class="red list-none"
                    >
                        {{ 'entrer une description' }}
                    </li>
                </div>
                <div class="col d-flex gap-1">
                    <div class="w-100">
                        <Datepicker
                            auto-apply
                            :close-on-auto-apply="false"
                            :max-date="new Date()"
                            :is-24="true"
                            locale="fr"
                            v-model="item.date"
                            :format="format"
                            placeholder="date début allergie"
                        />
                        <li
                            v-if="v$.collection.$each.$invalid"
                            class="red list-none"
                        >
                            {{ 'entrer une date' }}
                        </li>
                    </div>
                    <button
                        class="btn btn-danger btn-row-remove"
                        @click="removeRow"
                        v-show="index != 0"
                    >
                        <i class="fa-solid fa-x"></i>
                    </button>
                </div>
            </div>
        </div>
        <button class="btn mb-2 btn-row" @click="addRow">
            nouveaux champs
        </button>
    </div>
</template>
<script setup>
import Datepicker from '@vuepic/vue-datepicker';
import moment from 'moment';
import { ref, reactive } from 'vue';

const props = defineProps({
    form: {
        type: Array,
        required: true,
    },
    title: {
        type: String,
        required: true,
    },
    v$: {},
});

const format = (date) => {
    return moment(date).format('DD/MM/YYYY');
};

const addRow = () => {
    props.form.push({ description: [], date: [] });
};

const removeRow = (index) => {
    props.form.splice(index, 1);
};
</script>
