import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { Pagination } from '@/_types';

import { useToast } from 'vue-toast-notification';
import OrdonnanceService from '@/_services/OrdonnanceService';

@Module({ namespaced: true })
export default class OrdonanceModule extends VuexModule {
    ordonnances: Pagination | null = null;

    loadingStatus = false;

    $toast = useToast();

    // getters ordonance
    get gOrdonances() {
        return this.ordonnances;
    }

    //getters loading
    get gloadingStatus() {
        return this.loadingStatus;
    }

    @Mutation
    mloadingStatus(newStatus: boolean) {
        this.loadingStatus = newStatus;
    }

    // mutations
    @Mutation
    mOrdonnances(ordonances: Pagination) {
        this.ordonnances = ordonances;
    }

    // actions
    @Action
    async fetchOrdonnances(paginate = { page: 1 }) {
        await OrdonnanceService.getOrdonnances(paginate.page)
            .then((res: Pagination) => {
                console.log(res.data.data);
                this.context.commit('mOrdonnances', res.data.data);
            })
            .catch((error: any) => {
                console.log('error ordonnance', error);
            });
    }

    @Action
    async createdOrdannance(ordonnance: any) {
        this.context.commit('mloadingStatus', true);
        const ordonnances = {
            description: ordonnance.description,
            date: ordonnance.date,
            teleconsultation_id: ordonnance.teleconsultation_id,
        };
        await OrdonnanceService.createdOrdonnance(ordonnances)
            .then((res: Pagination) => {
                this.context.commit('mloadingStatus', false);
                const teleconsultation = {
                    ...this.context.rootState.teleconsultations
                        .teleconsultation,
                    ordonnances: res.data,
                };
                this.context.commit(
                    'teleconsultations/getTeleconsultationHandler',
                    teleconsultation,
                    { root: true }
                );
                this.$toast.success('Ordonnance crée avec succès', {
                    position: 'top-right',
                });
                return true;
            })
            .catch((error: any) => {
                this.context.commit('mloadingStatus', false);
                console.log('error ordonnance', error);
            });
    }

    @Action
    async deleteOrdannance(
        ordonnance = { relation_id: 0, ordonnance_id: 0, relation: '' }
    ) {
        this.context.commit('mloadingStatus', true);
        await OrdonnanceService.deleteOrdonnance(
            ordonnance.relation_id,
            ordonnance.ordonnance_id,
            ordonnance.relation
        )
            .then((res: Pagination | any) => {
                this.context.commit('mloadingStatus', false);
                const ordonnance =
                    this.context.rootState.teleconsultations.teleconsultation.ordonnances.filter(
                        (item: any) => item.id !== res.data.data.id
                    );
                const teleconsultation = {
                    ...this.context.rootState.teleconsultations
                        .teleconsultation,
                    ordonnances: ordonnance,
                };
                this.context.commit(
                    'teleconsultations/getTeleconsultationHandler',
                    teleconsultation,
                    { root: true }
                );
                this.$toast.success('Ordonnance supprimée avec succès', {
                    position: 'top-right',
                });
                return true;
            })
            .catch((error: any) => {
                this.context.commit('mloadingStatus', false);
                console.log('error ordonnance', error);
            });
    }
}
