import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { TypeTc } from '@/_types';

import TeleconsultationService from '@/_services/TeleconsultationService';

@Module({
    namespaced: true,
})
export default class TypesTeleconsultationModule extends VuexModule {
    typesTeleconsultation: TypeTc | null = null;
    typesAntecedent: TypeTc | null = null;

    get gTypesTeleconsultation() {
        return this.typesTeleconsultation;
    }

    get gTypesAntecedent() {
        return this.typesAntecedent;
    }

    @Mutation
    getTypesTeleconsultationHandler(types: TypeTc) {
        this.typesTeleconsultation = types;
    }

    @Mutation
    getTypeAntecedentHandler(types: TypeTc) {
        this.typesAntecedent = types;
    }

    @Action
    fetchTypesTeleconsultation(type: string | null) {
        TeleconsultationService.getTypesTeleconsultation(type)
            .then((res: any) => {
                this.context.commit(
                    'getTypesTeleconsultationHandler',
                    res.data?.data ?? res.data
                );
            })
            .catch((error: any) => {
                console.log('error', error);
            });
    }

    @Action
    fetchTypesAntecedent(type: string | null) {
        TeleconsultationService.getTypesTeleconsultation(type)
            .then((res: any) => {
                this.context.commit(
                    'getTypeAntecedentHandler',
                    res.data?.data ?? res.data
                );
            })
            .catch((error: any) => {
                console.log('error', error);
            });
    }
}
