<template lang="">
    <button
        type="button"
        @click="viewAntecedant"
        class="btn border d-flex align-items-center gap-1 m-2"
    >
        <i class="fa-solid fa-plus"></i> Ajouter
    </button>
    <Modal
        v-model="newAnt"
        title="Nouvel antécédent"
        bgcolor="#172b4d"
        colorTitle="white"
        width="lg"
    >
        <div class="my-3 d-flex justify-content-center" v-if="loader">
            <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin" />
        </div>
        <form v-if="!loader">
            <AntecedentForm :state="state" :v$="v$" />
            <div class="mt-3">
                <button
                    @click="createAntecedent(state)"
                    type="submit"
                    class="btn btn-primary"
                >
                    Enregistrer
                </button>
            </div>
        </form>
    </Modal>
</template>
<script setup lang="ts">
import Modal from './../Modal.vue';
import AntecedentForm from './AntecedentForm.vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import { ref, reactive, computed } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
    id: {
        type: Number,
        required: true,
    },
    teleconsultation_id: {
        type: Number,
        required: true,
    },
});

const store = useStore();
const newAnt = ref(false);

let state = reactive({
    type: {},
    description: '',
    date: null,
    dossier_medical_id: props.id,
    teleconsultation_id: props.teleconsultation_id,
});

const rules = computed(() => {
    return {
        description: { required },
        type: { required },
        date: { required },
    };
});

const v$ = useVuelidate(rules, state);

const viewAntecedant = () => {
    newAnt.value = true;
};

const createAntecedent = function (state: {}) {
    v$.value.$validate();
    if (!v$.value.$error) {
        store.dispatch('patient/createAntecedent', state).then(() => {
            newAnt.value = false;
            state = {
                type: {},
                description: '',
                date: null,
                dossier_medical_id: props.id,
                teleconsultation_id: props.teleconsultation_id,
            };
        });
    } else {
        //console.log(v$.value)
    }
};

const loader = computed(() => store.state.patient.loadingStatus);
</script>
