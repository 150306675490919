<template lang="">
    <div>
        <!-- <div class="mb-4" v-if="!anamnese?.length">
            <span>chargement en cours....</span>
        </div>  -->
        <div class="mb-3">
            <label class="typo__label">anamnèse</label>
            <VueMultiselect
                v-model="state.anamnese_id"
                deselect-label="Can't remove this value"
                track-by="id"
                label="fr_description"
                placeholder="Sélectionneer l'anamnèse"
                :options="anamnese ?? []"
                :searchable="true"
                :allow-empty="false"
                @search-change="asyncFindAnamneses"
            >
                <template v-slot:singleLabel="{ option }"
                    ><strong>{{ option.type }}</strong></template
                >
            </VueMultiselect>
            <li v-if="v$.anamnese_id.$error" class="red list-none">
                {{ "sélectionner l'anamnèse" }}
            </li>
        </div>
        <div class="mb-3">
            <label class="typo__label default"
                >Description<span class="red">*</span></label
            >
            <Tiny :height="300" :state="state" :v$="v$" />
        </div>
    </div>
</template>
<script setup>
import VueMultiselect from 'vue-multiselect';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import Tiny from '@/components/organisms/Tiny.vue';

const store = useStore();

const props = defineProps({
    state: {},
    v$: {},
});

const anamnese = computed(() => store.state.anamnese.anamnese?.data);

const isLoadingAnamneses = ref(false);

const asyncFindAnamneses = (query) => {
    if (query.length <= 3) {
        isLoadingAnamneses.value = true;
        store
            .dispatch('anamnese/searchAnamnese', { search: query, page: 1 })
            .then(() => {
                isLoadingAnamneses.value = false;
            });
    }
};
onMounted(() => {
    store.dispatch('anamnese/fetchAnamnese');
});
</script>
