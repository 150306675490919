<template lang="">
    <div>
        <button
            type="button"
            @click="viewRdv"
            class="btn border d-flex align-items-center gap-1 m-2"
        >
            <i class="fa-solid fa-plus"></i> Ajouter
        </button>
        <Modal
            v-model="rdv"
            title="Nouveau Rendez-Vous"
            bgcolor="#172b4d"
            colorTitle="white"
            width="lg"
        >
            <div class="my-3 d-flex justify-content-center" v-if="loader">
                <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin" />
            </div>

            <form v-if="!loader">
                <Rendez-Vous-Form :state="state" :v$="v$" />
                <div class="mt-3">
                    <button
                        @click="createRdv"
                        type="submit"
                        class="btn btn-primary"
                    >
                        Enregistrer
                    </button>
                </div>
            </form>
        </Modal>
    </div>
</template>
<script setup>
import RendezVousForm from './RendezVousForm.vue';
import Modal from './../Modal.vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import { ref, reactive, computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const props = defineProps({
    teleconsultation_id: {
        type: Number,
        required: true,
    },
    creator: {
        type: Number,
        required: true,
    },
    patient_id: {
        type: Number,
        required: true,
    },
    etablissement_id: {
        type: Number,
        required: true,
    },
});

const rdv = ref(false);

const viewRdv = () => {
    rdv.value = true;
};

const rules = computed(() => {
    return {
        date: { required },
        description: { required },
    };
});

const state = reactive({
    date: '',
    description: '',
    teleconsultation_id: props.teleconsultation_id,
    creator: props.creator,
    patient_id: props.patient_id,
    etablissement_id: props.etablissement_id,
});
const v$ = useVuelidate(rules, state);

const createRdv = () => {
    v$.value.$validate();
    if (!v$.value.$error) {
        store
            .dispatch('teleconsultations/createdRendezVous', {
                date: state.date,
                motifs: state.description,
                teleconsultation_id: props.teleconsultation_id,
                creator: props.creator,
                patient_id: props.patient_id,
                etablissement_id: props.etablissement_id,
            })
            .then(() => {
                rdv.value = false;
                state.date = '';
                state.description = '';
            });
    }
};

const loader = computed(() => store.state.teleconsultations.loadingStatus);
</script>
