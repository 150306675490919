<template lang="">
    <div class="row">
        <div class="col-md-12">
            <Allergie-Create :teleconsultation_id="teleconsultation.id" />
            <div class="table-responsive">
                <table class="table caption-top">
                    <caption>
                        Liste des allergies
                    </caption>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Description</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <div
                        class="position-absolute top-50 start-50"
                        v-if="loader"
                    >
                        <img
                            src="@/assets/icons/Spin.svg"
                            alt=""
                            class="btn-spin"
                        />
                    </div>
                    <tbody v-if="!loader">
                        <tr
                            v-for="(
                                allergie, index
                            ) in teleconsultation.allergies"
                            :key="`allergie${index}`"
                        >
                            <td>{{ index + 1 }}</td>
                            <td>{{ allergie?.description }}</td>
                            <td class="d-flex">
                                <span
                                    class="rounded-circle border p-2 cursor-pointer btn-delete"
                                    @click="
                                        deleteAllergie(
                                            teleconsultation.id,
                                            allergie.id,
                                            'teleconsultations'
                                        )
                                    "
                                    ><i class="fa-solid fa-trash red"></i
                                ></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, reactive, toRefs, computed, onMounted, inject } from 'vue';
import moment from 'moment';
import AllergieCreate from './AllergieCreate.vue';
import { useStore } from 'vuex';

const store = useStore();

const props = defineProps({
    teleconsultation: {},
});

const { teleconsultation } = toRefs(props);
const docState = ref('saved');

const dataAllergie = computed(() => store.state.allergie.paginations?.data);

const swal = inject('$swal');
const deleteAllergie = (relation_id, allergie_id, relation) => {
    swal.fire({
        title: 'Voulez-vous vraiment supprimer cette allergie?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Annuler',
        denyButtonText: `Supprimer`,
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isDenied) {
            store
                .dispatch('allergie/deleteAllergie', {
                    relation_id: relation_id,
                    allergie_id: allergie_id,
                    relation: relation,
                })
                .then(() => {
                    // swal.fire('Saved!', '', 'success');
                });
        } else if (result.isConfirmed) {
            swal.fire('Changes are not saved', '', 'info');
        }
    });
};

const loader = computed(() => store.state.allergie.loadingStatus);
</script>
