import { ENV } from './../api/env';

import axios from 'axios';

class MedicamentService {
    graphql: any;
    constructor() {
        this.graphql = axios.create({
            baseURL: ENV.apiMedicaments,
            headers: {
                'Content-Type': 'application/graphql',
                Accept: 'application/graphql',
            },
        });
    }

    async getMedicaments() {
        const query = `{
                medicaments(limit: 20) {
                    denomination
                    CIS
                    titulaires
                    surveillance_renforcee
                    groupes_generiques {libelle}
                    forme_pharmaceutique
                    voies_administration
                }

                substances(limit: 20) {
                  denominations
                }}`;
        try {
            const response = await this.graphql.post(`?query=${query}`);
            console.log(response.data.data);
            return response.data.data;
        } catch (error) {
            console.log(error);
        }
    }

    async filterMedicamentByDenomination(denomination: []) {
        const data = JSON.stringify(denomination);
        const query = `{
                medicaments(limit:50, denomination: {contains_all: ${data}}) {
                    denomination
                    CIS
                    titulaires
                    surveillance_renforcee
                    groupes_generiques {libelle}
                    forme_pharmaceutique
                    voies_administration
                }
            }`;
        try {
            const response = await this.graphql.post(`?query=${query}`);
            console.log(
                'response.data.data.medicaments ',
                response.data.data.medicaments
            );
            console.log('response.data ', response.data.data.medicaments);
            return response.data.data.medicaments;
        } catch (error) {
            console.log(error);
        }
    }
}

export default new MedicamentService();

// lien vers le repo github de l'api graphql pour médicament https://github.com/axel-op/api-bdpm-graphql
