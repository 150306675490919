<template lang="">
    <div class="">
        <router-view />
    </div>
</template>
<script>
export default {
    name: 'teleconsultation-layout',
};
</script>
